
<template>
  <div>
    <div>
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title><b>Novo Estado</b> </v-card-title>
          <v-divider></v-divider>
          <v-form ref="form" class="pa-8 mx-auto">
            <v-row dense>
              <v-col cols="12" md="12">
                <v-text-field
                  color="#007744"
                  v-model="estado.nome"
                  :error-messages="error.nome"
                  label="Nome:"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field
                  color="#007744"
                  v-model="estado.uf"
                  :error-messages="error.uf"
                  label="UF:"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-btn
              color="#007744"
              class="mr-4 mt-3 white--text"
              @click="salvar"
              :disabled="carregamentoSave"
              :loading="carregamentoSave"
            >
              <v-icon dark> mdi-check </v-icon>
              Salvar
            </v-btn>

            <v-btn color="error" class="mr-4 mt-3" @click="clear">
              <v-icon dark> mdi-close </v-icon>
            </v-btn>
          </v-form>
        </v-card>
      </v-dialog>

      <loading v-if="carregamento" />

      <v-card v-else flat>
        <v-card-title>
          <b class="titulo-header-page">Estados</b>
          <v-spacer></v-spacer>
          <v-btn small color="#007744" class="white--text" @click="clear">
            <v-icon dark> mdi-plus </v-icon>
            Novo Estado
          </v-btn>
        </v-card-title>

        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Nome</th>
                <th class="text-left">UF</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in estados" :key="index">
                <td class="text-left">{{ item.id }}</td>
                <td class="text-left">{{ item.nome }}</td>
                <td class="text-left">{{ item.uf }}</td>
                <td class="text-left">
                  {{ dateFormat(item.created_at) }}
                </td>
                <td class="text-left">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon v-bind="attrs" v-on="on" color="black">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="editar(item)">
                        <v-list-item-title
                          >Alterar informações</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item @click="excluir(item.id)">
                        <v-list-item-title>Excluir Estado</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
    <div class="text-left mt-5">
      <v-pagination
        v-model="pagination.page"
        :length="pagination.lastPage"
        :total-visible="pagination.perPage"
        color="#007744"
        v-if="estados.length > 0"
        @input="(page) => loadEstados(page)"
        @next="() => loadEstados(pagination.page)"
        @previous="() => loadEstados(pagination.page)"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import axios from "../../axios/service_private.js";
import Swal from "sweetalert2";

var moment = require("moment");
moment.locale("pt-br");

export default {
  data() {
    return {
      dialog: false,
      carregamento: false,
      carregamentoSave: false,
      estado: {
        nome: null,
        uf: null,
      },
      error: {
        nome: null,
        uf: null,
      },
      estados: [],
      pagination: {
        page: 1,
        perPage: 1,
        lastPage: 1,
      },
    };
  },
  methods: {
    excluir(id) {
      Swal.fire({
        title: `Estado será excluido`,
        text: `Deseja excluir registro?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007744",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: `Sim, pode continuar`,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await axios.delete(
            `/api/v1/pet-primavera/estado/deletar/${id}`
          );

          if (res.data.status) {
            Swal.fire({
              title: "Exclusão realizada!",
              text: res.data.message,
              icon: "success",
              confirmButtonColor: "#007744",
            });
            this.setup();
          } else {
            Swal.fire({
              title: "Erro encontrado!",
              text: res.data.message,
              icon: "warning",
              confirmButtonColor: "#d33",
            });
          }
        }
      });
    },
    editar(item) {
      this.dialog = !this.dialog;
      this.estado = item;
    },
    clear() {
      this.estado = {
        nome: null,
        uf: null,
      };

      this.error = {
        nome: null,
        uf: null,
      };

      this.dialog = !this.dialog;
    },
    salvar() {
      this.carregandoSave = true;

      let url = this.estado.id
        ? `/api/v1/pet-primavera/estado/alterar/${this.estado.id}`
        : "/api/v1/pet-primavera/estado/salvar";

      axios
        .post(url, this.estado)
        .then((res) => {
          if (res.data.status) {
            this.$toast(res.data.message, {
              type: "success",
            });

            this.clear();
            this.setup();

            this.carregandoSave = false;
          } else {
            this.$toast(res.data.message, {
              type: "error",
            });
            this.carregandoSave = false;
          }
        })
        .catch((res) => {
          if (res.response.data && res.response.data.validation) {
            this.error = res.response.data.validation;

            this.$toast(res.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(res.response.data.message, {
              type: "error",
            });
          }
          this.carregandoSave = false;
        });
    },
    dateFormat(param) {
      return moment(param).format("DD/MM/YYYY HH:mm");
    },
    async loadEstados(params) {
      this.carregamento = true;

      const estados = await axios.get("api/v1/pet-primavera/estados", {
        params: {
          page: params,
        },
      });

      if (estados.data.status) {
        this.estados = estados.data.estados.data;

        this.pagination = {
          page: estados.data.estados.page,
          perPage: estados.data.estados.perPage,
          lastPage: estados.data.estados.lastPage,
          total: estados.data.estados.total,
        };

        this.carregamento = false;
      }
    },
    setup() {
      this.$store.dispatch("verifyToken");
      this.loadEstados(this.pagination.page);
    },
  },
  mounted() {
    this.setup();
  },
};
</script>