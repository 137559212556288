<template>
  <div>
    <v-card elevation="0">
      <v-card-title>
        <b class="titulo-header-page">Relatório Mensal - Cremação, Óbitos, Adicionais e Quantidade de Pets</b>
      </v-card-title>
      <div class="ml-5 mr-5 d-flex">
        <v-row dense>
          <v-col cols="12" md="3">
            <v-autocomplete item-value="value" item-text="nome" label="Tipo Relatório:" color="#007744"
              v-model="relatorioSelecionado" :items="selecionarRelatorio" :disabled="limparFiltros"
              @input="() => this.liberarGerar()" auto-select-first outlined dense chips clearable
              deletable-chips></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field class="input" color="#007744" label="Data inicial:" v-model="params.inicio"
              :disabled="limparFiltros" @input="() => this.liberarGerar()" required type="date" outlined
              dense></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field class="input" v-model="params.fim" :disabled="limparFiltros"
              @input="() => this.liberarGerar()" color="#007744" type="date" label="Data final:" required outlined
              dense></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete item-value="id" item-text="nome" label="Cidade:" color="#007744" v-model="cidade"
              :items="cidades" v-if="liberarCidadePreencher" @input="() => this.liberarGerar()" auto-select-first
              outlined dense chips clearable deletable-chips></v-autocomplete>
          </v-col>
        </v-row>
      </div>
      <div class="spacer ml-5 mr-5 d-flex">
        <v-btn color="#007744" class="mr-4 white--text" :disabled="camposPreenchidos === false" small
          @click="gerarRelatorio()">
          <v-icon dark> mdi-plus </v-icon>
          Gerar Relatório
        </v-btn>

        <v-btn color="#007744" class="mr-4 white--text" small @click="gerarRelatorioExcel(relatorioSelecionado)"
          :disabled="!carregamento && relatorio.length === 0">
          <v-icon dark> mdi-microsoft-excel </v-icon>
        </v-btn>
        <template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="warning" class="mr-4 white--text" :disabled="!limparFiltros" small @click="limpar"
                v-bind="attrs" v-on="on">
                <v-icon dark> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Limpar Filtros</span>
          </v-tooltip>
        </template>
      </div>

      <loading v-if="carregamento" />

      <div v-else-if="relatorioGerado">
        <div v-if="(relatorioSelecionado === 'adicionaisTotal')">
          <v-row dense>
            <v-col cols="12">
              <widget icon="mdi-paw" class="centralizar-informacoes" subTitle="Total de Adicionais PET"
                color="blue darken-4" :title="String(relatorio[0].TOTAL_ADICIONAIS_PET)" />
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-simple-table dense class="mt-13 ml-5 mr-5" fixed-header height="440px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-for="header in retornarArrayPropriedadesObjeto(relatorio)" :key="header.key"
                    :class="header.align">{{ header.td }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, rowIndex) in relatorio" :key="rowIndex">
                  <td v-for="(header, colIndex) in retornarArrayPropriedadesObjeto(relatorio)" :key="colIndex"
                    :class="header.align">
                    {{ header && header.is_format_real ? formatValor(item[header.chave]) : item[header.chave] }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr v-for="header in retornarArrayPropriedadesObjeto([valores], true)" :key="header">
                  <th colspan="12">
                    TOTAL: <b>{{ formatValor(valores[header]) }}</b>
                  </th>
                </tr>
                <tr>
                  <th colspan="12">
                    {{ textTotalRegistro }}
                  </th>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </div>
      </div>
      <found v-else />
    </v-card>
  </div>
</template>

<script>
var moment = require("moment");
moment.locale("pt-br");

import ExcelJS from 'exceljs'
import axios from '../../axios/service_private.js';

export default {
  data() {
    return {
      carregamento: false,
      cidade: null,
      limparFiltros: false,
      liberarCidadePreencher: false,
      cidades: [
        { "id": 1, "nome": "Abadiânia" },
        { "id": 2, "nome": "Alto Piquiri " },
        { "id": 3, "nome": "Altônia " },
        { "id": 4, "nome": "Amambai" },
        { "id": 5, "nome": "Anápolis" },
        { "id": 6, "nome": "Antônio João" },
        { "id": 7, "nome": "Aral Moreira" },
        { "id": 8, "nome": "Bela Vista" },
        { "id": 9, "nome": "Caarapó" },
        { "id": 10, "nome": "Campo Limpo" },
        { "id": 11, "nome": "Cascavel " },
        { "id": 12, "nome": "Cascavel Emvida" },
        { "id": 13, "nome": "Colider" },
        { "id": 14, "nome": "Coronel Sapucaia" },
        { "id": 15, "nome": "Cruzeiro do Oeste" },
        { "id": 16, "nome": "Douradina" },
        { "id": 17, "nome": "Dourados" },
        { "id": 18, "nome": "Eldorado" },
        { "id": 19, "nome": "Feliz Natal" },
        { "id": 20, "nome": "Goioerê " },
        { "id": 21, "nome": "Guaíra" },
        { "id": 22, "nome": "Guaraniaçu" },
        { "id": 23, "nome": "Icaraíma" },
        { "id": 24, "nome": "Iguatemi" },
        { "id": 25, "nome": "Ipiranga do Norte" },
        { "id": 26, "nome": "Iporã" },
        { "id": 27, "nome": "Itaporã" },
        { "id": 28, "nome": "Itaquirai" },
        { "id": 29, "nome": "Ivaté" },
        { "id": 30, "nome": "Juti" },
        { "id": 31, "nome": "Laguna Carapã" },
        { "id": 32, "nome": "Marechal Cândido Rondon" },
        { "id": 33, "nome": "Mariluz" },
        { "id": 34, "nome": "Mundo Novo" },
        { "id": 35, "nome": "Navirai" },
        { "id": 36, "nome": "Nova Alvorada do Sul" },
        { "id": 37, "nome": "Palotina" },
        { "id": 38, "nome": "Ponta Porã" },
        { "id": 39, "nome": "Rio Brilhante" },
        { "id": 40, "nome": "Santa Helena" },
        { "id": 41, "nome": "Santa Tereza do Oeste" },
        { "id": 42, "nome": "São Gabriel do Oeste" },
        { "id": 43, "nome": "Silvânia" }
      ],
      selecionarRelatorio: [
        { nome: 'Cremação', value: 'os' },
        { nome: 'Óbitos', value: 'obitos' },
        { nome: 'Adicionais', value: 'adicionais' },
        { nome: 'Quantidade Pets', value: 'adicionaisTotal' }
      ],
      relatorioSelecionado: null,
      relatorio: [],
      params: {
        inicio: null,
        fim: null,
      },
      camposPreenchidos: false,
      relatorioGerado: null,
      textTotalRegistro: '',
      valores: null
    };
  },
  methods: {
    retornarArrayPropriedadesObjeto(resultado, keys = false) {
      if (resultado.length > 0) {
        let propriedades = Object.keys(resultado[0]);

        if (keys) return propriedades;

        propriedades.map((header, index) => {
          let align = this.tableCondicao(["VALOR", "DATA", "NUMERO", "CONTRATO"], header, 'text-center', 'text-left');
          let is_format_real = this.tableCondicao(['VALOR', 'TOTAL'], header, true, false);
          let td = (header.replace('_', ' ')).toUpperCase();

          propriedades[index] = { chave: header, align, is_format_real, td };

        })
        return propriedades;
      }
      return [];
    },
    tableCondicao(fields, field, condition_1, condition_2) {
      console.log(field.toUpperCase())
      return fields.some(e => ((field).toUpperCase()).startsWith(e)) ? condition_1 : condition_2;
    },
    liberarGerar() {
      if (this.relatorioSelecionado === 'os') {
        if (this.relatorioSelecionado && (this.params.inicio && this.params.fim)) {
          this.limparFiltros = true;
          this.camposPreenchidos = true;
        } else {
          this.camposPreenchidos = false;
        }
      } else {
        if (this.cidade && this.relatorioSelecionado && (this.params.inicio && this.params.fim)) {
          this.limparFiltros = true;
          this.camposPreenchidos = true;
        } else {
          this.camposPreenchidos = false;
        }
      }
    },
    liberarCidade() {
      this.liberarCidadePreencher = !(this.relatorioSelecionado === 'os' || this.relatorioSelecionado === null);
    },
    limpar() {
      this.showNumeroRegistros = false;
      this.numeroRegistros = null;
      this.camposPreenchidos = false;
      this.relatorioGerado = false;
      this.cidade = null;
      this.params.fim = null;
      this.limparFiltros = false;
      this.relatorio = [];
    },
    async gerarRelatorio() {
      this.relatorioGerado = true;
      this.carregamento = true;

      let params = {
        relatorio: this.relatorioSelecionado,
        data_inicial: this.params.inicio,
        data_fim: this.params.fim
      };

      if (['adicionais', 'obitos', 'adicionaisTotal'].includes(this.relatorioSelecionado)) {
        params['cidade_id'] = this.cidade;
      }

      try {
        const relatorioSelecionado = await axios.get('/api/v1/pet-primavera/relatorio/relatorios-mensais', { params });

        if (relatorioSelecionado.data.status) {
          this.relatorio = relatorioSelecionado.data.relatorio.registros;
          this.valores = relatorioSelecionado.data.relatorio.totais ?? {};
          this.textTotalRegistro = `TOTAL DE REGISTROS DO RELATÓRIO ${this.tipoRelatorio()}: (${relatorioSelecionado.data.relatorio.items}).`;
        }

      } catch (res) {
        if (res.response.data.message && res.response.data.message.error) {
          this.$toast(res.response.data.message.error, {
            type: "error",
          });
        } else {
          this.$toast(res.toString(), {
            type: "error",
          });
        }
      }
      this.carregamento = false;
    },
    async gerarRelatorioExcel(tipo) {
      try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(`Relatório - Pet Particular`);

        const columns = {
          adicionais: [
            { header: 'CONTRATO', key: 'contrato', width: 20 },
            { header: 'NOME', key: 'nome', width: 20 },
            { header: 'PORTE', key: 'porte', width: 20 },
            { header: 'VALOR DE ADESÃO', key: 'valorAdesao', width: 10 },
            { header: 'VALOR MENSAL', key: 'valorMensal', width: 10 },
            { header: 'DATA DO CONTRATO', key: 'dataContrato', width: 20 },
          ],
          obitos: [
            { header: 'CONTRATO', key: 'contrato', width: 20 },
            { header: 'NOME DO CLIENTE', key: 'nomeCliente', width: 20 },
            { header: 'NOME DO DEPENDENTE', key: 'nomeDependente', width: 20 },
            { header: 'DATA DE FALECIMENTO', key: 'dataFalecimento', width: 20 }
          ],
          os: [
            { header: 'OS', key: 'numeroOrdemServico', width: 10 },
            { header: 'ATENDENTE', key: 'atendente', width: 20 },
            { header: 'CONTRATANTE', key: 'nomeContratante', width: 20 },
            { header: 'PAGAMENTO', key: 'formaPagamento', width: 20 },
            { header: 'CPF', key: 'cpfContratante', width: 20 },
            { header: 'CIDADE', key: 'cidadeContratante', width: 20 },
            { header: 'PET', key: 'pet', width: 20 },
            { header: 'VALOR DO KIT', key: 'valorKit', width: 10 },
            { header: 'VALOR TOTAL', key: 'valorTotal', width: 10 }
          ]
        };

        const dataMapping = {
          adicionais: item => ({
            contrato: item.CONTRATO,
            nome: item.NOME,
            porte: item.PORTE,
            valorAdesao: item.VALOR_ADESAO,
            valorMensal: item.VALOR_MENSAL,
            dataContrato: item.DATA_CONTRATO
          }),
          obitos: item => ({
            contrato: item.CONTRATO,
            nomeCliente: item.NOME_CLIENTE,
            nomeDependente: item.NOME_DEPENDENTE,
            dataFalecimento: item.DATA_FALECIMENTO
          }),
          os: item => ({
            numeroOrdemServico: item.numeroOrdemServico,
            atendente: item.atendente,
            nomeContratante: item.nomeContratante,
            formaPagamento: item.formaPagamento,
            cpfContratante: item.cpfContratante,
            cidadeContratante: item.cidadeContratante,
            pet: item.PET,
            valorKit: item.valorKit,
            valorTotal: item.valorTotal
          })
        };

        worksheet.columns = columns[tipo];
        this.relatorio.forEach(item => worksheet.addRow(dataMapping[tipo](item)));

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = downloadUrl;
        link.setAttribute("download", `Relatório - ${this.tipoRelatorio()} - Pet Particular`);
        document.body.appendChild(link);
        link.click();
        link.remove();

      } catch (error) {
        this.$toast(error, { type: "error" });
      }
    },
    tipoRelatorio() {
      switch (this.relatorioSelecionado) {
        case 'os':
          return 'CREMADOS';
        case 'obitos':
          return 'ÓBTIDOS';
        case 'adicionais':
          return 'ADICIONAIS';
        case 'quantidadePets':
          return 'QUANTIDADES DE ADICIONAIS PETS NA FILIAL'
        default:
          break;
      };
    },
    getCurrentDateFormatted() {
      this.params.inicio = moment().startOf("month").format("YYYY-MM-DD");
      this.params.fim = moment().format("YYYY-MM-DD");
    },
    formatValor(number) {
      return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    setup() {
      this.$store.dispatch("verifyToken");
      this.getCurrentDateFormatted();
    },
  },
  watch: {
    'relatorioSelecionado'() {
      this.liberarCidade();
    }
  },
  mounted() {
    this.setup();
  },
};
</script>
