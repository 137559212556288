<template>
    <div>
        <v-card>
            <v-card-title class="mx-auto">
                <strong class="titulo-stepper">{{ titulo }}</strong>
            </v-card-title>

            <v-divider></v-divider>

            <v-form ref="form" class="pa-8 mx-auto">
                <fieldset class="pa-4 mb-4">
                    <legend>Forma de Pagamento:</legend>

                    <v-row dense>
                        <v-col cols="12" md="6" v-if="tipoContrato(campos.forma_pagamento)">
                            <v-autocomplete
                                v-model="forma_pagamento.forma_pag"
                                item-value="tipo"
                                item-text="tipo"
                                label="Forma de Pagamento:"
                                color="#007744"
                                :items="tipo_de_pagamento"
                                auto-select-first
                                outlined
                                dense
                                chips
                                clearable
                                deletable-chips
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.valor_total_servico)">
                            <v-text-field
                                color="#007744"
                                v-model="forma_pagamento.valor_total_servico"
                                :error-messages="error.valor_total_servico"
                                label="Valor total do serviço:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.data_primeira_mensalidade)">
                            <v-text-field
                                color="#007744"
                                v-model="forma_pagamento.data_primeira_mensalidade"
                                :error-messages="error.data_primeira_mensalidade"
                                label="Data da primeira mensalidade:"
                                aria-required
                                type="date"
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.dia_pagamento)">
                            <v-text-field
                                color="#007744"
                                v-model="forma_pagamento.dia_pagamento"
                                :error-messages="error.dia_pagamento"
                                label="Dia do Pagamento:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </fieldset>
            </v-form>
        </v-card>
    </div>
</template>

<script>

export default {
    name: 'formaPagamento',
    data: () => ({
        forma_pagamento: {
            id: null,
            forma_pag: null,
            valor_total_servico: null,
            data_primeira_mensalidade: null,
            dia_pagamento: null
        },
        error: {
            forma_pag: null,
            valor_total_servico: null,
            data_primeira_mensalidade: null
        },
        tipo_de_pagamento: [
            { tipo: 'Boleto', value: 'boleto' },
            { tipo: 'Link', value: 'link' },
            { tipo: 'Cartão', value: 'cartao' },
            { tipo: 'PIX', value: 'pix' },
            { tipo: 'Dinheiro', value: 'dinheiro' }
        ],
        botao_liberado: false
    }),
    props: {
        campos: {
            type: Object
        },
        titulo: {
            type: String
        },
        ordem: {
            type: Number
        },
        infoCliente: {
            type: Object
        }
    },
    methods: {
        emitDados() {
            this.$emit('dadosPagamento', this.forma_pagamento);
        },
        tipoContrato(array) {
            return array.includes(this.ordem);
        },
        preencherCampos() {
            if (this.infoCliente.contratante_id_pagamento) {
                this.forma_pagamento.id = this.infoCliente.id_pagamento_table;
                this.forma_pagamento.forma_pag = this.infoCliente.forma_pagamento;
                this.forma_pagamento.data_primeira_mensalidade = this.infoCliente.data_primeira_mensalidade;
                this.forma_pagamento.valor_total_servico = this.infoCliente.valor_total_servico ? this.infoCliente.valor_total_servico : null;
            }
        },
        liberarBotao() {
            if (this.titulo === 'Contrato PET Primavera - Particular' || this.titulo === 'Contrato PET Primavera - Particular - Multiplos pets') {
                if (this.forma_pagamento.forma_pag !== null &&
                    this.forma_pagamento.valor_total_servico !== null
                ) {
                    this.botao_liberado = true;
                }
            }

            if (this.titulo === 'Contrato PET Primavera - Dourados - Plano PET individualizado') {
                if (this.forma_pagamento.forma_pag !== null &&
                    this.forma_pagamento.data_primeira_mensalidade !== null
                ) {
                    this.botao_liberado = true;
                }
            }
        }
    },
    watch: {
        infoCliente(value) {
            if (value !== null && value !== undefined) this.preencherCampos();
        },

        forma_pagamento: {
            handler() {
                this.$emit('atualizar-botao-liberado', this.botao_liberado);
            },
            deep: true
        }
    },
};
</script>

<style scoped>

.titulo-stepper {
    color: #007744;
    margin: auto;
}

</style>