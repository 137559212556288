<template class="">
  <div class="center margin">
    <v-img
      alt="Pet Primavera - WEB"
      class="shrink mr-2"
      contain
       src="img/logo.png"
      width="100"
    />

    <div class="text-carregamento">
      <h1> {{message != null ? message : 'Preparando sistema, aguarde.'}} </h1>
    </div>

    <center class="mt-10">
      <v-progress-circular indeterminate color="#007744"></v-progress-circular>
    </center>
  </div>
</template>

<script>
export default {
  name: "App",
  props: {
    message:{
      type: String,
      default: null,
      require: false,
    }
  },
  data: () => ({
    pack: require("../../../package.json"),
  }),
};
</script>


<style scoped>
.center {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;

  padding: 40px 40px;
}

.margin {
  margin-top: 1rem;
}

.text-carregamento {
  margin-top: 1rem;
}

h1 {
  font-size: 1rem;
  color: #007744;
  text-align: center;
}
</style>