<template>
  <div>
    <v-dialog v-model="dialog" max-width="950" persistent>
      <v-card>
        <v-card-title><b> {{ titulo }}</b>
          <v-switch v-model="titular.is_permuta" label="É permuta?" class="pl-3 pt-1" color="#007744"></v-switch>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref="form" class="pa-8 mx-auto">
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field color="#007744" v-model="titular.nome" :error-messages="error.nome" label="Nome completo:"
                required outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field color="#007744" v-model="titular.cpf" :error-messages="error.cpf" v-mask="'###.###.###-##'"
                label="CPF:" required outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field color="#007744" v-model="titular.rg" :error-messages="error.rg" label="RG:" required
                outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete class="mb-5" item-value="id" item-text="descricao" label="Plano:" color="#007744"
                :items="planos" v-model="titular.plano_id" auto-select-first outlined dense chips clearable
                deletable-chips :error-messages="error.plano_id"></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete class="mb-5" item-value="nome" item-text="nome" label="Forma de Pagamento::"
                color="#007744" :items="formasPagamentos" v-model="titular.forma_pagamento" auto-select-first outlined
                dense chips clearable deletable-chips :error-messages="error.forma_pagamento"></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field color="#007744" v-model="titular.dia_vencimento" :error-messages="error.dia_vencimento"
                label="Dia pagamento:" type="number" required outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field color="#007744" v-model="titular.rua_endereco" :error-messages="error.rua_endereco"
                label="Endereço(RUA):" required outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field color="#007744" v-model="titular.bairro_endereco" :error-messages="error.bairro_endereco"
                label="Bairro:" required outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field color="#007744" v-model="titular.numero_lote_endereco"
                :error-messages="error.numero_lote_endereco" label="Número/Lote:" required outlined
                dense></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field color="#007744" v-model="titular.data_assinatura_contrato"
                :error-messages="error.data_assinatura_contrato" label="Data Assinatura do Contrato:" type="date"
                required outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field color="#007744" v-model="titular.data_primeira_mensalidade"
                :error-messages="error.data_primeira_mensalidade" label="Data Primeira Mensalidade:" required
                type="date" outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete class="mb-5" item-value="id" item-text="nome" label="Estado:" v-model="estado_id"
                color="#007744" :items="estados" auto-select-first outlined dense chips clearable deletable-chips
                @input="(value) => loadCidades(value)"></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete class="mb-5" item-value="id" item-text="nome" label="Cidade:" color="#007744"
                :items="cidades" :disabled="cidades.length <= 0" v-model="titular.cidade_id" auto-select-first outlined
                dense chips clearable deletable-chips :error-messages="error.cidade_id"></v-autocomplete>
            </v-col>

            <v-col cols="12" md="12">
              <fieldset class="pa-1 mb-6 pt-5 pl-3 pr-5">
                <legend><v-icon>mdi-pin</v-icon><strong>Anexe contratos de Inclusão, Exclusão, etc</strong></legend>
                <v-row dense>
                  <v-col cols="12" md="5">
                    <v-autocomplete class="mb-5" item-value="nome" item-text="nome" label="Tipo de Contrato:"
                      color="#007744" :items="tipo_contratos" v-model="nomeContratosAnexados" auto-select-first outlined
                      dense chips clearable deletable-chips prepend-icon="mdi-file-document-outline"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-file-input multiple show-size counter class="mb-5" outlined dense color="#007744"
                      label="Contratos para anexar:" v-model="selectedContratos" ref="fileInput"
                      accept="image/*,application/pdf"></v-file-input>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-btn outlined class="buttom-send" color="success" @click="enviarContratosAvulsos"
                      :disabled="selectedContratos.length <= 0">
                      <v-icon dark> mdi-send </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="12">
                    <div class="d-flex flex-wrap" v-if="nomeTermos !== null && nomeTermos.length >= 1">
                      <div 
                        v-for="(termo, index) in nomeTermos" 
                        :key="index"
                      >
                        <v-chip
                          v-if="termo.tipo !== 'PET PRIMAVERA - DECLARAÇÃO DE CANCELAMENTO DO PLANO PARTICULAR'"
                          class="ml-3 mb-6 v-chip-size scrolling-cell"
                          close-icon="mdi-close-outline"
                          color="#007744"
                          label
                          outlined
                        >
                          <span class="inner-cell">{{ termo.nome ? termo.nome : termo }}</span>
                        </v-chip>
                      </div>
                    </div>

                    <div v-else>
                      <v-chip label outlined class="ml-3 mb-6" color="warning">{{ nenhumArquivo }}</v-chip>
                    </div>
                  </v-col>
                </v-row>
              </fieldset>
            </v-col>

            <v-col cols="12" md="12" v-if="titular && titular.id">
              <v-alert class="mb-10" dense outlined type="error">
                <p v-if="!titular.data_cancelamento">
                  <strong> CUIDADO!</strong> Ao preencher esses campos,<strong>
                    o plano será cancelado.</strong>
                </p>

                <p v-else> Este plano está <strong>cancelado!</strong> </p>

                <div class="d-flex">
                  <v-text-field class="mt-3 mr-3" color="#007744" v-model="data_cancelamento_contrato"
                    :error-messages="error.data_cancelamento" label="Data Cancelamento:" type="date" required outlined
                    dense></v-text-field>

                  <template>
                    <v-file-input multiple show-size counter class="mt-3" outlined dense color="#007744"
                      label="Termo de Cancelamento:" v-model="selectedFile" ref="fileInput"
                      accept="image/*,application/pdf"></v-file-input>
                  </template>

                  <v-btn v-if="titular.data_cancelamento" outlined class="space" color="success" @click="enviarTermo(false)"
                    :disabled="selectedFile.length < 0">
                    <v-icon dark> mdi-upload </v-icon>
                  </v-btn>
                </div>

                <div class="d-flex flex-wrap" v-if="titular.data_cancelamento && nomeTermos.length >= 1">
                  <div v-for="(termo, index) in nomeTermos" :key="index">
                    <v-chip v-if="termo.tipo === 'PET PRIMAVERA - DECLARAÇÃO DE CANCELAMENTO DO PLANO PARTICULAR'" class="mr-3 mb-3 v-chip-size"
                      close-icon="mdi-close-outline" color="orange" label link outlined @click="visualizarTermo(termo.id)">
                      {{ termo.nome }}
                    </v-chip>
                  </div>
                </div>

                <div v-if="titular.data_cancelamento && nomeTermos.length < 1">Termo(s) de Cancelamento <strong>não
                    anexado(s)</strong>!</div>

                <v-textarea class="mt-3" color="#007744" v-model="justificativa_cancelamento_contrato"
                  :error-messages="error.justificativa_cancelamento" label="Justificativa Cancelamento:" required
                  outlined dense></v-textarea>
              </v-alert>
            </v-col>
          </v-row>


          <v-btn color="#007744" class="mr-4 white--text" @click="salvar" :disabled="carregandoSave"
            v-if="this.titular && !this.isCancelado" :loading="carregandoSave">
            <v-icon dark> mdi-check </v-icon>
            Salvar
          </v-btn>

          <v-btn color="warning" class="mr-4" :disabled="carregandoSave" v-else
            @click="reverterCancelamentoContratoTitular(titular.id)" :loading="carregandoSave">
            <v-icon dark> mdi-check </v-icon>
            REVERTER CANCELAMENTO
          </v-btn>

          <v-btn color="error" class="mr-4" @click="clear">
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>
    <v-card class="mb-1" flat>
      <v-card-title>
        <b class="titulo-header-page">Titulares Contratos</b>
      </v-card-title>
      <div>
        <v-row dense>
          <v-col cols="12" md="12">
            <div class="pl-5 pr-5">
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-autocomplete item-value="value" item-text="nome" label="Tipo filtro:" color="#007744"
                    v-model="buscar.tipo" :items="filtros" auto-select-first outlined dense chips clearable
                    deletable-chips></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-if="buscar.tipo === 'cpf'" color="#007744" v-model="buscar.search"
                    v-mask="'###.###.###-##'" label="Digite o CPF:" @input="buscarContrato(pagination.page)" required
                    outlined dense></v-text-field>
                  <v-text-field v-else color="#007744" v-model="buscar.search" label="Digite sua pesquisa:"
                    @input="buscarContrato(pagination.page)" required outlined dense></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col cols="12" md="12">
            <div class="pl-5 pr-5 margin-negativa">
              <div class="titulo-header-page-legenda mt-4">
                <b>Legenda:</b>
              </div>
              <v-row dense>
                <v-col cols="12" md="4">
                  <v-chip label class="total" outlined> Contrato Ativo </v-chip>
                </v-col>
                <v-col cols="12" md="4">
                  <v-chip label class="total" color="yellow lighten-3">
                    Contrato em Carência
                  </v-chip>
                </v-col>

                <v-col cols="12" md="4">
                  <v-chip color="error" label class="total">
                    Contrato Cancelado
                  </v-chip>
                </v-col>

                <v-col cols="12" md="4">
                  <v-chip color="red lighten-3" label class="total">
                    Contrato (ATR)
                  </v-chip>
                </v-col>

                <v-col cols="12" md="4">
                  <v-chip color="green lighten-4" label class="total">
                    Contrato Permuta
                  </v-chip>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <loading v-if="carregamento" />

    <found v-else-if="!carregamento && titulares.length === 0" />

    <v-row justify="center" v-else class="pl-3 pr-3 pt-3">
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(item, i) in titulares" :key="i">
          <v-expansion-panel-header verificarContrato disable-icon-rotate :color="item.data_cancelamento != null
            ? 'red'
            : item.parcelas_atr.length > 0
              ? 'red lighten-3'
              : item.is_permuta
                ? 'green lighten-4'
                : item.is_carencia
                  ? 'yellow lighten-3'
                  : ''
            ">
            <b><v-icon class="mr-2">mdi-people</v-icon>{{ item.nome }}</b>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list flat>
              <v-subheader>Dados Cadastrais</v-subheader>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="mt-1"><b>ID:</b> {{ item.id }}</v-list-item-title>

                  <v-list-item-title class="mt-1"><b>Contrato:</b> {{ item.contrato }}</v-list-item-title>

                  <v-list-item-title class="mt-1"><b>Nome completo:</b> {{ item.nome }}</v-list-item-title>

                  <v-list-item-title class="mt-1"><b>Plano:</b> {{ item.descricao }}</v-list-item-title>

                  <v-list-item-title class="mt-1"><b>Último Pagamento:</b>
                    {{ dateFormat(item.ultimo_pagamento) }}</v-list-item-title>

                  <v-list-item-title class="mt-1"><b>Assinatura de Contrato:</b>
                    {{
                      dateFormat(item.data_assinatura_contrato)
                    }}</v-list-item-title>

                  <v-list-item-title class="mt-1"><b>Primeira Mensalidade:</b>
                    {{
                      dateFormat(item.data_primeira_mensalidade)
                    }}</v-list-item-title>

                  <v-list-item-title class="mt-1" v-if="item.data_cancelamento != null"><b>PLANO CANCELADO EM:</b>
                    {{ dateFormat(item.data_cancelamento) }}</v-list-item-title>

                  <v-list-item-title class="mt-1" v-if="item.parcelas_atr && item.parcelas_atr.length > 0">
                    <b>Parcelas em atrasos(ATR):</b>

                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">N° Parcela</th>
                            <th class="text-center">Valor Apagar</th>
                            <th class="text-center">Data de Referente</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in item.parcelas_atr" :key="index" class="red lighten-3">
                            <td class="text-center">
                              {{ item.numero_parcela }}
                            </td>
                            <td class="text-center">
                              {{ formatPreco(item.valor_apagar) }}
                            </td>
                            <td class="text-center">
                              {{ dateFormat(item.data_referente) }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-btn color="#007744" class="mr-2 white--text" small
                  :disabled="!item.is_ativo || item.data_cancelamento !== null"
                  @click="redirecionar(`/contrato?titular=${item.id}`)">
                  Abrir contrato
                </v-btn>

                <v-btn color="error" class="mr-2" small v-if="item.is_carencia === 'Em Carência'">
                  {{ item.is_carencia }}
                </v-btn>

                <v-btn color="warning" small outlined @click="editar(item)" :loading="loadingEdit">
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>

                <v-btn color="error" class="ml-2" v-if="item.is_ativo" outlined small
                  @click="desativarAtivarContrato(item)">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
                <v-btn color="success" class="ml-2" v-else outlined small @click="desativarAtivarContrato(item)">
                  <v-icon> mdi-check </v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="text-left mt-5">
        <v-pagination v-model="pagination.page" :length="pagination.lastPage" :total-visible="pagination.perPage"
          color="#007744" @input="(page) => loadTitulares(page)" v-if="titulares.length > 0"
          @next="() => loadTitulares(pagination.page)" @previous="() => loadTitulares(pagination.page)"></v-pagination>
      </div>
    </v-row>
  </div>
</template>

<script>
import axios from "../../axios/service_private.js";
import Swal from "sweetalert2";
import global from "../../globais.js";

const moment = require("moment");

export default {
  data: () => ({
    global: global,
    dialog: false,
    especie_id: null,
    estado_id: null,
    titulo: "Cadastrar Novo titular",
    titulares: [],
    planos: [],
    estados: [],
    cidades: [],
    tipo_contato: [
      { tipo: 'EMAIL' },
      { tipo: 'TELEFONE' },
      { tipo: 'WHATSAPP' }
    ],
    formasPagamentos: [
      { id: 1, nome: "BOLETO" },
      { id: 2, nome: "PIX" },
    ],
    portes: [
      { id: 0, nome: "P" },
      { id: 1, nome: "M" },
      { id: 2, nome: "G" },
      { id: 3, nome: "GG" },
    ],
    titular: {
      plano_id: null,
      cidade_id: null,
      nome: null,
      cpf: null,
      rg: null,
      rua_endereco: null,
      bairro_endereco: null,
      dia_vencimento: null,
      forma_pagamento: null,
      numero_lote_endereco: null,
      data_cancelamento: null,
      data_assinatura_contrato: null,
      data_primeira_mensalidade: null,
      justificativa_cancelamento: null,
      is_permuta: false,
      is_ativo: true,
      contatos: [
        {
          tipo: "",
          contato: "",
        },
      ],
    },
    error: {
      plano_id: null,
      cidade_id: null,
      nome: null,
      cpf: null,
      rg: null,
      rua_endereco: null,
      bairro_endereco: null,
      dia_vencimento: null,
      forma_pagamento: null,
      numero_lote_endereco: null,
      data_assinatura_contrato: null,
      data_primeira_mensalidade: null,
      is_permuta: false,
    },
    carregamento: false,
    carregandoSave: false,
    pagination: {
      page: 1,
      perPage: 1,
      lastPage: 1,
    },
    buscar: {
      tipo: "cpf",
      search: "",
    },
    filtros: [
      { nome: "Contrato", value: "contrato" },
      { nome: "CPF", value: "cpf" },
      { nome: "Nome", value: "nome" },
    ],
    user: null,
    /// Cancelar contrato
    data_cancelamento: null,
    isCancelado: false,
    isAtivo: true,
    data_cancelamento_contrato: null,
    justificativa_cancelamento_contrato: null,
    selectedFile: [],
    salvarCancelar: false,
    /// Para anexar diferentes tipos de contratos
    nomeContratosAnexados: null,
    selectedContratos: [],
    anexos: [],
    contratosEnviados: false,
    idTemporario: null,

    nomeTermos: [],
    nenhumArquivo: '',
    loadingEdit: false,

    tipo_contratos: [
      { id: 1, nome: 'Contrato PET Primavera - Dourados - Plano PET individualizado' },
      { id: 2, nome: 'TABLET VENDAS - TERMO DE INCLUSAO DE DEPENDENTE PET PRIMAVERA ASSOCIADO' },
      { id: 3, nome: 'PET PRIMAVERA - DECLARACAO DE EXCLUSAO DE DEPENDENTE PET ASSOCIADO' },
      { id: 4, nome: 'PET PRIMAVERA - DECLARACAO DE EXCLUSAO DE DEPENDENTE PET PARTICULAR' },
      { id: 5, nome: 'PET PRIMAVERA - DECLARACAO DE INCLUSAO DE DEPENDENTE PET PARTICULAR' },
      { id: 6, nome: 'COMPROVANTE DE PAGAMENTO E OUTROS' }
    ]
  }),
  methods: {
    async carregarTermo(id) {
      await axios
        .get(`api/v1/pet-primavera/cliente/carregar-termo-cancelamento/${id}`)
        .then((res) => {
          this.anexos = res.data.data;
          return this.anexos;       
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.status === false) {
            return;
          } else {
            this.$toast('Não foi possivel carregar termos!', {
              type: 'error'
            });
          }
        });
    },
    formatPreco(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    dateFormatInput(param) {
      return moment(param).format("YYYY-MM-DD");
    },
    loadPlanos() {
      axios
        .get("/api/v1/pet-primavera/planos-sem-pagination")
        .then((response) => {
          if (response.data.status) {
            this.planos = response.data.planos;
          }
        });
    },
    loadEstados() {
      axios
        .get("/api/v1/pet-primavera/estados-sem-pagination")
        .then((response) => {
          if (response.data.status) {
            this.estados = response.data.estados;
          }
        });
    },
    loadCidades(id) {
      axios
        .get(`/api/v1/pet-primavera/cidades-selecionar-estado/${id}`)
        .then((response) => {
          if (response.data.status) {
            this.cidades = response.data.cidades;
          }
        });
    },
    async buscarContrato(params) {
      if (this.buscar && this.buscar.search.length === 0)
        return this.loadTitulares();
      if (
        this.buscar &&
        this.buscar.search.length < 14 &&
        this.buscar.tipo === "cpf"
      )
        return false;

      this.carregamento = true;

      const titulares = await axios.get(
        `api/v1/pet-primavera/titular/${this.buscar.tipo}?search=${this.buscar.search}`,
        {
          params: {
            page: params,
          },
        }
      );

      if (titulares.data.status) {
        this.titulares = titulares.data.titulares.data;

        this.pagination = {
          page: titulares.data.titulares.page,
          perPage: titulares.data.titulares.perPage,
          lastPage: titulares.data.titulares.lastPage,
          total: titulares.data.titulares.total,
        };
        this.carregamento = false;
      }
    },
    async loadTitulares(params) {
      this.carregamento = true;

      const titulares = await axios.get("api/v1/pet-primavera/titulares", {
        params: {
          page: params,
        },
      });

      if (titulares.data.status) {
        this.titulares = titulares.data.titulares.data;

        this.pagination = {
          page: titulares.data.titulares.page,
          perPage: titulares.data.titulares.perPage,
          lastPage: titulares.data.titulares.lastPage,
          total: titulares.data.titulares.total,
        };
        this.carregamento = false;
      }
    },
    async visualizarTermo(id) {
      await this.carregarTermo(id);
      const termo = this.anexos;
      const fileURL = termo[0].url;
      window.open(fileURL, '_blank');
    },
    async carregarNomeTermo(id) {
      const nomes = await axios.get(`api/v1/pet-primavera/cliente/carregar-nomes-termos/${id}`);
      if (nomes.data.status) {
        this.nomeTermos = nomes.data.data;
      } else {
        this.nenhumArquivo = 'Nenhum tipo de Contrato associado a este Titular!';
      }
    },
    async editar(item) {
      this.loadingEdit = true;
      this.loadPlanos();

      if (item && item.data_cancelamento === null) {
        this.idTemporario = item.id;
        await this.carregarNomeTermo(item.id);
      }

      this.titular = item;
      this.estado_id = null;
      this.titulo =
        item && item.id
          ? "Alterar informações do titular"
          : "Novo titular(Contrato)";

      if (item && item.id) {
        this.estado_id = item.estado_id;
        this.loadCidades(item.estado_id);
      }

      if (item && item.data_cancelamento != null) {
        this.isCancelado = true;
        this.isAtivo = true;
        this.data_cancelamento_contrato = this.dateFormatInput(item.data_cancelamento);
        this.justificativa_cancelamento_contrato = item.justificativa_cancelamento;

        await this.carregarNomeTermo(item.id);
      }

      if (item && item.data_assinatura_contrato != null) {
        this.titular.data_assinatura_contrato = this.dateFormatInput(
          item.data_assinatura_contrato
        );
      }

      if (item && item.data_primeira_mensalidade != null) {
        this.titular.data_primeira_mensalidade = this.dateFormatInput(
          item.data_primeira_mensalidade
        );
      }

      this.loadingEdit = false;
      this.dialog = !this.dialog;
    },
    desativarAtivarContrato(titular) {
      Swal.fire({
        title: !titular.is_ativo
          ? "O contrato será ativado!"
          : "O contrato será desativado!",
        text: `Algumas ações são irreversíveis. Tem certeza que deseja ${!titular.is_ativo ? "ativar" : "desativar"
          } o contrato?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#007744",
        cancelButtonText: "Cancelar",
        confirmButtonText: !titular.is_ativo ? "Ativar" : "Desativar",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let url = !titular.is_ativo
            ? "/api/v1/pet-primavera/titular/ativar/" + titular.id
            : "/api/v1/pet-primavera/titular/desativar/" + titular.id;
          let res = await axios.post(url);
          if (res.data.status) {
            Swal.fire({
              title: !titular.is_ativo
                ? "O contrato foi ativado!"
                : "O contrato foi desativado!",
              text: res.data.message,
              icon: "success",
              confirmButtonColor: "#007744",
            });
            this.loadTitulares();
          } else {
            Swal.fire({
              title: "Houve um erro!",
              text: res.data.message,
              icon: "warning",
              confirmButtonColor: "#d33",
            });
          }
        }
      });
    },
    enviarContratosAvulsos() {
      let formData = new FormData();

      this.selectedContratos.forEach(file => {
        formData.append('files[]', file);
      });

      let tipo = 3;
      if (this.nomeContratosAnexados === 'COMPROVANTE DE PAGAMENTO E OUTROS') tipo = 2;

      axios
        .post(`api/v1/pet-primavera/cliente/enviar-documentos-titular/${this.titular.id}/titular/${tipo}/${this.nomeContratosAnexados}`, formData)
        .then((res) => {
          if (res.data.status) {
            this.contratosEnviados = true;
            this.$toast(res.data.message, {
              type: 'success'
            });

            this.selectedFile = [];
            this.selectedContratos = [];
            this.nomeContratosAnexados = null;
            this.nomeTermos = res.data.data;
          }
        })
        .catch((res) => {
          if (res.response.data && res.response.data.validation) {
            this.error = res.response.data.validation;

            this.$toast(res.response.data.message, {
              type: "error",
            });

          } else {
            this.$toast(res.response.data.message, {
              type: "error",
            });
          }
        });
    },
    async enviarTermo(salvarCancelar=false) {

      this.salvarCancelar = salvarCancelar;
      let formData = new FormData();

      this.selectedFile.forEach(file => {
        formData.append('files[]', file);
      });

      await axios
        .post(`api/v1/pet-primavera/cliente/cancelar-contrato-termo/${this.titular.id}/titular/3`, formData)
        .then(async (res) => {
          if (res.data.status) {
            this.$toast(res.data.message, {
              type: "success",
            });

            this.selectedFile = [];
          }
        })
        .catch((res) => {
          if (res.response.data && res.response.data.validation) {
            this.error = res.response.data.validation;

            this.$toast(res.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(res.response.data.message, {
              type: "error",
            });
          }
        });
    },
    salvar() {
      this.carregandoSave = true;

      if (this.data_cancelamento_contrato != null) {
        this.titular.data_cancelamento = this.data_cancelamento_contrato;
        this.titular.justificativa_cancelamento = this.justificativa_cancelamento_contrato;
        this.titular.is_ativo = !this.isAtivo;
        this.isCancelado = true;
      }
      
      if (this.selectedFile.length > 0) {
        this.salvarCancelar = true;
        this.enviarTermo(true);
      }

      axios
        .post(`/api/v1/pet-primavera/titular/alterar/${this.titular.id}`, this.titular)
        .then((res) => {
          if (res.data.status) {
            this.$toast(res.data.message, {
              type: "success",
            });

            this.clear();
            this.setup();

            this.carregandoSave = false;
          } else {
            this.$toast(res.data.message, {
              type: "error",
            });
            this.carregandoSave = false;
          }
        })
        .catch((res) => {
          if (res.response.data && res.response.data.validation) {
            this.error = res.response.data.validation;

            this.$toast(res.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(res.response.data.message, {
              type: "error",
            });
          }

          this.carregandoSave = false;
        });
    },
    reverterCancelamentoContratoTitular(id) {
      this.carregandoSave = true;

      Swal.fire({
        title: "Reativar contrato - reverter cancelamento",
        text: "Deseja realizar essa ação?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007744",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim",
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            axios
              .post(`/api/v1/pet-primavera/titular/reverter-cancelamento/${id}`)
              .then((res) => {
                if (res.data.status) {
                  this.$toast(res.data.message, {
                    type: "success",
                  });

                  this.data_cancelamento_contrato = null;
                  this.justificativa_cancelamento_contrato = null;
                  this.selectedFile = null;
                  this.isCancelado = false;
                  this.isAtivo = true;

                  this.loadTitulares();
                  this.editar({});
                  this.carregandoSave = false;
                } else {
                  this.$toast(res.data.message, {
                    type: "error",
                  });
                  this.carregandoSave = false;
                }
              })
              .catch((res) => {
                if (res.response.data && res.response.data.validation) {
                  this.errorBaixa = res.response.data.validation;

                  this.$toast(res.response.data.message, {
                    type: "error",
                  });
                } else {
                  this.$toast(res.response.data.message, {
                    type: "error",
                  });
                }
                this.carregandoSave = false;
              });
          } else {
            this.carregandoSave = false;
          }
        })
        .catch(() => {
          this.$toast("Sem permissão para executar esse comando!", {
            type: "error",
          });
        });
    },
    clear() {
      if (!this.titular.id) this.loadPlanos();

      this.titular = {
        plano_id: null,
        cidade_id: null,
        nome: null,
        cpf: null,
        rg: null,
        rua_endereco: null,
        bairro_endereco: null,
        dia_vencimento: null,
        forma_pagamento: null,
        numero_lote_endereco: null,
        data_cancelamento: null,
        data_assinatura_contrato: null,
        data_primeira_mensalidade: null,
        is_permuta: false,
        justificativa_cancelamento: null,
        contatos: [
          {
            tipo: "",
            contato: "",
          },
        ],
      };

      this.error = {
        plano_id: null,
        cidade_id: null,
        nome: null,
        cpf: null,
        rg: null,
        rua_endereco: null,
        bairro_endereco: null,
        dia_vencimento: null,
        forma_pagamento: null,
        numero_lote_endereco: null,
        is_permuta: false,
        contatos: [
          {
            tipo: "",
            contato: "",
          },
        ],
      };

      this.dialog = !this.dialog;

      this.isCancelado = false;
      this.isAtivo = true;
      this.data_cancelamento_contrato = null;
      this.justificativa_cancelamento_contrato = null;
      this.selectedContratos = [];
      this.selectedFile = [];
      this.nomeContratosAnexados = null;
      this.contratosEnviados = false;
      this.idTemporario = null;
      this.carregandoSave = false;
      this.salvarCancelar = false;
      this.nomeTermos = null;
    },
    criarNovoCampo() {
      this.titular.contatos.push({
        tipo: "",
        contato: "",
      });
    },
    removerCampo(index) {
      this.titular.contatos.splice(index, 1);
    },
    redirecionar(route) {
      this.$router.push({ path: route, replace: true  });
    },
    setup() {
      this.$store.dispatch("verifyToken");
      this.loadTitulares(this.pagination.page);
      this.loadEstados();
    }
  },
  watch: {
    contratosEnviados(value) {
      if (value === true) this.carregarTermo(this.idTemporario, 3);
    }
  },
  mounted() {
    this.setup();
    this.user = JSON.parse(localStorage.getItem("user"));
  },
};
</script>

<style scoped>
.space {
  margin: 14px 5px;
}

.v-chip-size {
  max-width: 150px;
  min-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.informacoes {
  margin-bottom: 2rem;
}

fieldset {
  border: 1px solid lightgray;
}

.itemsContainerContact {
  font-size: 0.8rem;
  font-weight: 400;
  width: 45%;
}

.imagem-pet img {
  width: 40px;
  cursor: pointer;
}

.info-parcela {
  margin-top: -20px;
  margin-bottom: 1rem;
}

.carencia {
  background: rgba(248, 248, 117, 0.904);
}

.deletado {
  background: rgba(212, 97, 89, 0.904);
}

.obito {
  background: rgba(211, 157, 7, 0.904);
}

.divide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cartao-pet {
  padding: 20px 20px;
}

.cartao-pet p,
.cartao-pet b {
  font-size: 14px;
}

.cartao-pet b {
  font-size: 10px;
  padding-right: 20px;
}

.cartao-pet img {
  width: 100%;
  height: 100%;
}

.imagem-pet-icon-horizontal img {
  width: 20px;
}

.imagem-pet-icon img {
  width: 35px;
}

.texto_cartao_pet {
  position: absolute;
  margin-top: -95px;
}

.texto_cartao_raca {
  position: absolute;
  margin-top: -75px;
}

.texto_cartao_tutor {
  position: absolute;
  margin-top: -60px;
}

.dados-cartao {
  padding-left: 10px;
  padding-right: 10px;
}

.cartao-pet {
  padding: 20px 20px;
}

.cartao-pet p,
.cartao-pet b {
  font-size: 14px;
}

.cartao-pet b {
  font-size: 10px;
  padding-right: 20px;
}

.cartao-pet img {
  width: 100%;
  height: 100%;
}

.imagem-pet-icon-horizontal img {
  width: 20px;
}

.imagem-pet-icon img {
  width: 35px;
}

.texto_cartao_pet {
  position: absolute;
  margin-top: -95px;
}

.texto_cartao_raca {
  position: absolute;
  margin-top: -75px;
}

.texto_cartao_tutor {
  position: absolute;
  margin-top: -60px;
}

.texto_cartao_cpf {
  position: absolute;
  margin-top: -45px;
}

.dados-cartao {
  padding-left: 10px;
  padding-right: 10px;
}

.margin-negativa {
  margin-top: -30px;
  margin-bottom: 30px;
}

.estornarObito {
  background: orange;
}

#extrato {
  padding: 20px 20px;
}

#extrato img {
  width: 160px;
}

#extrato h1 {
  font-size: 18px;
  text-align: right;
}

#extrato .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

#extrato ul {
  list-style: none;
}

#extrato ul li {
  text-align: right;
}

.v-list-item__title {
  white-space: pre-line;
}

.primary-text {
  color: #007744;
  list-style: none;
  padding: 10px 10px;
  font-weight: bold;
}

.copiar_codigo_pix {
  padding: 20px 20px;
  background: #ececec;
  text-align: center;
}

.baixado {
  background: #007744;
  color: #ddd;
}

.cancelado {
  background: rgb(209, 67, 67);
  color: #ddd;
}

.total {
  width: 100%;
}

.card-titular-contrato {
  padding: 15px;
  width: 350px;
}

.title-card-termos {
  font-size: 1rem;
}

.link-termo {
  margin: 15px;
}

.link-termo:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #007744;
}

.term-type {
  white-space: normal;
  /* Permite quebra de linha normal */
  word-wrap: break-word;
  /* Quebra a linha entre as palavras */
  display: inline-block;
  /* Garante que as propriedades funcionem corretamente */
  max-width: 100%;
  /* Ajusta para o tamanho disponível */
}

.v-chip-size-titular-contrato {
  width: 100%;
}

.buttom-send {
  margin-left: 5px;
  margin-top: 2px;
}

.scrolling-cell {
  overflow: hidden;
}

.inner-cell {
  white-space: nowrap;
  animation: scrollText 7s linear infinite;
}

@keyframes scrollText {
  0% {
    transform: translateX(30%);
  }

  100% {
    transform: translateX(-110%);
  }
}
</style>
