<template>
    <div>
        <v-card >
            <v-card-title class="mx-auto">
                <strong class="titulo-stepper">{{ titulo }}</strong>
            </v-card-title>

            <v-divider></v-divider>

            <v-form ref="form" class="pa-8 mx-auto">
                <v-row dense>
                    <v-col cols="12" md="6">
                        <v-text-field
                            color="#007744"
                            v-model="contratante.nome_documento"
                            :error-messages="error.nome_documento"
                            label="Nome do Documento*:"
                            aria-required
                            type="text"
                            outlined
                            dense
                            prepend-icon="mdi-file-document-edit"
                            @input="liberarBotao"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <strong>
                            <v-checkbox
                                class="ml-5 mt-1"
                                color="success"
                                label="É Permuta?"
                                v-model="contratante.is_permuta"
                            ></v-checkbox>
                        </strong>
                    </v-col>
                </v-row>

                <fieldset class="pa-4 mb-4">
                    <legend>Dados Contratante:</legend>

                    <v-row dense>
                        <v-col cols="12" md="6" v-if="tipoContrato(campos.cpf)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.cpf"
                                :error-messages="error.cpf"
                                v-mask="'###.###.###-##'"
                                label="CPF*:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                append-icon="mdi-magnify"
                                @click:append="buscarCliente"
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.n_contrato)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.n_contrato"
                                :error-messages="error.n_contrato"
                                label="N° Contrato*:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.data_cadastro)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.data_cadastro"
                                :error-messages="error.data_cadastro"
                                label="Data Cadastro*:"
                                aria-required
                                type="date"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.data_cancelamento)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.data_cancelamento"
                                :error-messages="error.data_cancelamento"
                                label="Data Cancelamento*:"
                                aria-required
                                type="date"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.mensalidades_vencidas)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.mensalidades_vencidas"
                                :error-messages="error.mensalidades_vencidas"
                                label="Mensalidades Vencidas*:"
                                aria-required
                                type="number"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.motivo_cancelamento)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.motivo_cancelamento"
                                :error-messages="error.motivo_cancelamento"
                                label="Motivo Cancelamento*:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.nome_completo)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.nome"
                                :error-messages="error.nome"
                                label="Nome Completo*:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.rg)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.rg"
                                :error-messages="error.rg"
                                label="RG*:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.nacionalidade)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.nacionalidade"
                                :error-messages="error.nacionalidade"
                                label="Nacionalidade*:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.naturalidade)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.naturalidade"
                                :error-messages="error.naturalidade"
                                label="Naturalidade*:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.data_nasc)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.data_nasc"
                                :error-messages="error.data_nasc"
                                label="Data de Nascimento*:"
                                aria-required
                                type="date"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.religiao)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.religiao"
                                :error-messages="error.religiao"
                                label="Religiao*:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.telefone)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.telefone"
                                :error-messages="error.telefone"
                                label="Telefone*:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.telefone2)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.telefone2"
                                :error-messages="error.telefone2"
                                label="Telefone2*:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.data_assinatura)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.data_assinatura"
                                label="Data Assinatura*:"
                                aria-required
                                type="date"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.email)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.email"
                                :error-messages="error.email"
                                label="Email*:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.estado_civil)">
                            <v-text-field
                                color="#007744"
                                v-model="contratante.estado_civil"
                                :error-messages="error.estado_civil"
                                label="Estado Civil*:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                                :disabled="desabilitar"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </fieldset>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import axios from '../../../../axios/service_private';

export default {
    name: "contratanteInfo",
    data: () => ({
        cliente: [],
        qtdd: 0,
        contratante: {
            id: null,
            is_permuta: false,
            nome_documento: null,
            nome_contrato: null,
            cpf: null,
            nome: null,
            rg: null,
            data_assinatura: null,
            email: null,
            naturalidade: null,
            nacionalidade: null,
            religiao: null,
            data_nasc: null,
            estado_civil: null,
            telefone: null,
            telefone2: null,
            n_contrato: null, // Só irá aparecer quando o cliente já tiver plano
            data_cadastro: null, // Só irá aparecer quando o cliente já tiver plano
            data_cancelamento: null, // Só irá aparecer quando o cliente já tiver plano
            mensalidades_vencidas: null, // Só irá aparecer quando o cliente já tiver plano
            motivo_cancelamento: null
        },
        botao_liberado: false,
        jaCadastrado: [false, false, false, false, false, false],
        desabilitar: true
    }),
    props: {
        campos: {
            type: Object
        },
        titulo: {
            type: String
        },
        ordem: {
            type: Number
        },
        error: {
            type: Object,
            default: () => []
        }
    },
    methods: {
        async buscarCliente() {
            try {
                this.desabilitar = !this.desabilitar;
                const cliente = await axios.get("api/v1/pet-primavera/contratos/verificar-cliente", {
                    params: {
                        cpf_cliente: this.contratante.cpf,
                        ordem: this.ordem
                    }
                });

                if(cliente.data.status) {
                    this.cliente = cliente.data.data;
                    if (this.cliente.nome !== null) this.jaCadastrado[0] = true;
                    if (this.cliente.logradouro !== null) this.jaCadastrado[1] = true;
                    if (this.cliente.valor_adesao !== null) this.jaCadastrado[2] = true;
                    if (this.cliente.valor_mensalidade !== null) this.jaCadastrado[3] = true;
                    if (this.cliente.forma_pagamento !== null) this.jaCadastrado[4] = true;
                    if (this.cliente.pets.length > 0) this.jaCadastrado[5] = true;

                    this.$emit('ja-cadastrado', this.jaCadastrado);
                    this.$toast(cliente.data.message, {
                        type: "success",
                    });
                    const tipoCliente = cliente.data.message === 'Cliente já possui plano particular!' ? 'particular' : 'avulso';
                    this.atribuirCampos(this.cliente, tipoCliente);
                } else {
                    this.$toast(cliente.data.message, {
                        type: "success",
                    });
                    this.$emit('ja-cadastrado', this.jaCadastrado);
                }
            } catch(error) {
                this.$toast(error.response.data.message, {
                    type: "error",
                });
            }
        },
        atribuirCampos(cliente, tipo) {
            if(tipo === 'particular') {
                this.contratante.id = cliente.id;
                this.contratante.nome = cliente.nome;
                this.contratante.rg = cliente.rg;
                this.contratante.email = 'ti@paxprimavera.com';
                this.contratante.naturalidade = 'Não definido';
                this.contratante.nacionalidade = 'Brasileira';
                this.contratante.religiao = 'Não definido';
                this.contratante.data_nasc = null;
                this.contratante.estado_civil = 'Não definido';
                this.contratante.telefone = 'Não definido';
                this.contratante.telefone2 = 'Não definido';
                this.contratante.n_contrato = cliente.contrato;
            } else {
                this.contratante.id = cliente.id_contratante_table;
                this.contratante.nome = cliente.nome;
                this.contratante.rg = cliente.rg;
                this.contratante.email = cliente.email;
                this.contratante.naturalidade = cliente.naturalidade;
                this.contratante.nacionalidade = cliente.nacionalidade;
                this.contratante.religiao = cliente.religiao;
                this.contratante.data_nasc = cliente.data_nasc ? this.formatDate(String(cliente.data_nasc)) : null;
                this.contratante.estado_civil = 'Não definido';
                this.contratante.telefone = cliente.telefone;
                this.contratante.telefone2 = cliente.telefone2;
            }
            this.$emit('data-contratante', this.cliente);
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        resetForm() {

            let clearTitulo = null;
            let clearCliente = [];
            let clearContratante = {
                cpf: null,
                nome: null,
                rg: null,
                email: null,
                naturalidade: null,
                nacionalidade: null,
                religiao: null,
                data_nasc: null,
                estado_civil: null,
                telefone: null,
                telefone2: null
            };

            this.titulo = clearTitulo;
            this.cliente = clearCliente,
            this.contratante = clearContratante;
        },
        tipoContrato(array) {
            return array.includes(this.ordem);
        },
        liberarBotao() {
            if (this.titulo === 'Contrato PET Primavera - Particular' || this.titulo === 'Contrato PET Primavera - Particular - Multiplos pets') {
                if (this.contratante.nome_documento !== null &&
                    this.contratante.cpf !== null &&
                    this.contratante.nome !== null &&
                    this.contratante.rg !== null &&
                    this.contratante.data_assinatura !== null &&
                    this.contratante.email !== null &&
                    this.contratante.naturalidade !== null &&
                    this.contratante.nacionalidade !== null &&
                    this.contratante.data_nasc !== null &&
                    this.contratante.telefone !== null &&
                    this.contratante.telefone2 !== null
                ) {
                    this.botao_liberado = true;
                }
            }

            if (this.titulo === 'Contrato PET Primavera - Dourados - Plano PET individualizado') {
                if (this.contratante.nome_documento !== null &&
                    this.contratante.cpf !== null &&
                    this.contratante.nome !== null &&
                    this.contratante.rg !== null &&
                    this.contratante.naturalidade !== null &&
                    this.contratante.nacionalidade !== null &&
                    this.contratante.data_nasc !== null &&
                    this.contratante.religiao !== null &&
                    this.contratante.telefone !== null &&
                    this.contratante.telefone2 !== null &&
                    this.contratante.data_assinatura !== null &&
                    this.contratante.email !== null &&
                    this.contratante.estado_civil !== null
                ) {
                    this.botao_liberado = true;
                }
            }

            if (this.titulo === 'PET PRIMAVERA - DECLARAÇÃO DE CANCELAMENTO DO PLANO PARTICULAR') {
                if (this.contratante.nome_documento !== null &&
                    this.contratante.cpf !== null &&
                    this.contratante.n_contrato !== null &&
                    this.contratante.data_cadastro !== null &&
                    this.contratante.data_cancelamento !== null &&
                    this.contratante.mensalidades_vencidas !== null &&
                    this.contratante.motivo_cancelamento !== null &&
                    this.contratante.nome !== null &&
                    this.contratante.rg !== null &&
                    this.contratante.telefone !== null &&
                    this.contratante.data_assinatura !== null
                ) {
                    this.botao_liberado = true;
                }
            }

            if (this.titulo === 'TABLET VENDAS - TERMO DE INCLUSÃO DE DEPENDENTE PET PRIMAVERA ASSOCIADO' ||
                this.titulo === 'PET PRIMAVERA - DECLARAÇÃO DE EXCLUSÃO DE DEPENDENTE PET ASSOCIADO' ||
                this.titulo === 'PET PRIMAVERA - DECLARAÇÃO DE EXCLUSÃO DE DEPENDENTE PET PARTICULAR' ||
                this.titulo === 'PET PRIMAVERA - DECLARAÇÃO DE INCLUSÃO DE DEPENDENTE PET PARTICULAR'
            ) {
                if (this.contratante.nome_documento !== null &&
                    this.contratante.cpf !== null &&
                    this.contratante.n_contrato !== null &&
                    this.contratante.nome !== null &&
                    this.contratante.rg !== null &&
                    this.contratante.telefone !== null &&
                    this.contratante.data_assinatura !== null &&
                    this.contratante.email !== null
                ) {
                    this.botao_liberado = true;
                }
            }
            
        },
        emitDados() {
            this.contratante.nome_contrato = this.titulo;
            this.$emit('dadosContratanteInfo', this.contratante);
        }
    },
    watch: {
        contratante: {
            handler() {
                this.$emit('atualizar-botao-liberado', this.botao_liberado);
            },
            deep: true
        }
    }
};
</script>

<style scoped>

.titulo-stepper {
    color: #007744;
    margin: auto;
}

</style>