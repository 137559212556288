<template>
  <div>
    <v-dialog v-model="dialog" max-width="900" persistent>
      <v-card>
        <v-card-title
          ><b> {{ titulo }}</b></v-card-title
        >
        <v-divider></v-divider>

        <v-form ref="form" class="pa-8 mx-auto">
          <fieldset class="pa-4 mb-4">
            <legend>Porte P:</legend>

            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.limite_p"
                  :error-messages="error.limite_p"
                  label="Quantidade PET 'P':"
                  required
                  type="number"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_adesao_com_resgate_p"
                  :error-messages="error.adicional_adesao_com_resgate_p"
                  label="Valor adesão adicional C.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_adesao_sem_resgate_p"
                  :error-messages="error.adicional_adesao_sem_resgate_p"
                  label="Valor adesão adicional S.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_mensalidade_com_resgate_p"
                  :error-messages="error.adicional_mensalidade_com_resgate_p"
                  label="Valor mensalidade adicional C.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_mensalidade_sem_resgate_p"
                  :error-messages="error.adicional_mensalidade_sem_resgate_p"
                  label="Valor mensalidade adicional S.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </fieldset>

          <fieldset class="pa-4 mb-4">
            <legend>Porte M:</legend>

            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.limite_m"
                  :error-messages="error.limite_m"
                  label="Quantidade PET 'M':"
                  required
                  type="number"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_adesao_com_resgate_m"
                  :error-messages="error.adicional_adesao_com_resgate_m"
                  label="Valor adesão adicional C.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_adesao_sem_resgate_m"
                  :error-messages="error.adicional_adesao_sem_resgate_m"
                  label="Valor adesão adicional S.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_mensalidade_com_resgate_m"
                  :error-messages="error.adicional_mensalidade_com_resgate_m"
                  label="Valor mensalidade adicional C.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_mensalidade_sem_resgate_m"
                  :error-messages="error.adicional_mensalidade_sem_resgate_m"
                  label="Valor mensalidade adicional S.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </fieldset>

          <fieldset class="pa-4 mb-4">
            <legend>Porte G:</legend>

            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.limite_g"
                  :error-messages="error.limite_g"
                  label="Quantidade PET 'G':"
                  required
                  type="number"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_adesao_com_resgate_g"
                  :error-messages="error.adicional_adesao_com_resgate_g"
                  label="Valor adesão adicional C.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_adesao_sem_resgate_g"
                  :error-messages="error.adicional_adesao_sem_resgate_g"
                  label="Valor adesão adicional S.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_mensalidade_com_resgate_g"
                  :error-messages="error.adicional_mensalidade_com_resgate_g"
                  label="Valor mensalidade adicional C.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_mensalidade_sem_resgate_g"
                  :error-messages="error.adicional_mensalidade_sem_resgate_g"
                  label="Valor mensalidade adicional S.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </fieldset>

          <fieldset class="pa-4 mb-4">
            <legend>Porte GG:</legend>

            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.limite_gg"
                  :error-messages="error.limite_gg"
                  label="Quantidade PET 'GG':"
                  required
                  type="number"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_adesao_com_resgate_gg"
                  :error-messages="error.adicional_adesao_com_resgate_gg"
                  label="Valor adesão adicional C.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_adesao_sem_resgate_gg"
                  :error-messages="error.adicional_adesao_sem_resgate_gg"
                  label="Valor adesão adicional S.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_mensalidade_com_resgate_gg"
                  :error-messages="error.adicional_mensalidade_com_resgate_gg"
                  label="Valor mensalidade adicional C.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  color="#007744"
                  v-model="plano.adicional_mensalidade_sem_resgate_gg"
                  :error-messages="error.adicional_mensalidade_sem_resgate_gg"
                  label="Valor mensalidade adicional S.Resgate:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </fieldset>

          <fieldset class="pa-4 mb-4">
            <legend>Plano:</legend>

            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  color="#007744"
                  v-model="plano.valor_mensalidade"
                  :error-messages="error.valor_mensalidade"
                  label="Valor mensalidade:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  color="#007744"
                  v-model="plano.valor_adesao"
                  :error-messages="error.valor_adesao"
                  label="Valor adesão:"
                  type="number"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  color="#007744"
                  v-model="plano.carencia_plano"
                  :error-messages="error.carencia_plano"
                  label="Dias Carência:"
                  required
                  outlined
                  type="number"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-textarea
                  color="#007744"
                  v-model="plano.descricao"
                  :error-messages="error.descricao"
                  label="Descrição(Sobre o plano):"
                  required
                  outlined
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
          </fieldset>

          <v-btn
            color="#007744"
            class="mr-4 mt-3 white--text"
            @click="salvar"
            :disabled="carregandoSave"
            :loading="carregandoSave"
          >
            <v-icon dark> mdi-check </v-icon>
            Salvar
          </v-btn>

          <v-btn color="error" class="mr-4 mt-3" @click="clear">
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <loading v-if="carregamento" />

    <found v-else-if="!carregamento && planos.length === 0" />

    <v-card flat v-else class="mt-1">
      <v-card-title>
        <b class="titulo-header-page">Planos</b>
        <v-spacer></v-spacer>
        <v-btn color="#007744" small class="white--text" @click="clear">
          <v-icon dark> mdi-plus </v-icon>
          Novo Plano
        </v-btn>
      </v-card-title>

      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Plano</th>
              <th class="text-center">Valor adesão</th>
              <th class="text-center">Valor mensalidade</th>
              <th class="text-center">Criado</th>
              <th class="text-left">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in planos" :key="index">
              <td class="text-left">{{ item.id }}</td>
              <td class="text-left">
                {{ item.descricao }}
              </td>
              <td class="text-center">{{ formatPreco(item.valor_adesao) }}</td>
              <td class="text-center">
                {{ formatPreco(item.valor_mensalidade) }}
              </td>
              <td class="text-center">
                {{ dateFormat(item.created_at) }}
              </td>
              <td class="text-left">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on" color="black">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="editar(item)">
                      <v-list-item-title>Alterar informações</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="excluir(item.id)">
                      <v-list-item-title>Excluir plano</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <div class="text-left mt-5">
      <v-pagination
        v-model="pagination.page"
        :length="pagination.lastPage"
        :total-visible="pagination.perPage"
        color="#007744"
        @input="(page) => loadPlanos(page)"
        v-if="planos.length > 0"
        @next="() => loadPlanos(pagination.page)"
        @previous="() => loadPlanos(pagination.page)"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import axios from "../../axios/service_private.js";
import Swal from "sweetalert2";

var moment = require("moment");
moment.locale("pt-br");

export default {
  data: () => ({
    dialog: false,
    titulo: "Cadastrar Novo Plano",
    planos: [],
    plano: {
      limite_p: null,
      limite_m: null,
      limite_g: null,
      limite_gg: null,
      descricao: null,
      adicional_adesao_sem_resgate_p: null,
      adicional_adesao_com_resgate_p: null,
      adicional_mensalidade_com_resgate_p: null,
      adicional_mensalidade_sem_resgate_p: null,
      adicional_adesao_sem_resgate_m: null,
      adicional_adesao_com_resgate_m: null,
      adicional_mensalidade_com_resgate_m: null,
      adicional_mensalidade_sem_resgate_m: null,
      adicional_adesao_sem_resgate_g: null,
      adicional_adesao_com_resgate_g: null,
      adicional_mensalidade_com_resgate_g: null,
      adicional_mensalidade_sem_resgate_g: null,
      adicional_adesao_sem_resgate_gg: null,
      adicional_adesao_com_resgate_gg: null,
      adicional_mensalidade_com_resgate_gg: null,
      adicional_mensalidade_sem_resgate_gg: null,
      valor_adesao: null,
      carencia_plano: null,
      valor_mensalidade: null,
    },
    error: {
      limite_p: null,
      limite_m: null,
      limite_g: null,
      limite_gg: null,
      descricao: null,
      adicional_adesao_sem_resgate_p: null,
      adicional_adesao_com_resgate_p: null,
      adicional_mensalidade_com_resgate_p: null,
      adicional_mensalidade_sem_resgate_p: null,
      adicional_adesao_sem_resgate_m: null,
      adicional_adesao_com_resgate_m: null,
      adicional_mensalidade_com_resgate_m: null,
      adicional_mensalidade_sem_resgate_m: null,
      adicional_adesao_sem_resgate_g: null,
      adicional_adesao_com_resgate_g: null,
      adicional_mensalidade_com_resgate_g: null,
      adicional_mensalidade_sem_resgate_g: null,
      adicional_adesao_sem_resgate_gg: null,
      adicional_adesao_com_resgate_gg: null,
      adicional_mensalidade_com_resgate_gg: null,
      adicional_mensalidade_sem_resgate_gg: null,
      valor_adesao: null,
      carencia_plano: null,
      valor_mensalidade: null,
    },
    carregamento: false,
    carregandoSave: false,
    pagination: {
      page: 1,
      perPage: 1,
      lastPage: 1,
    },
  }),
  methods: {
    formatPreco(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    excluir(id) {
      Swal.fire({
        title: `Plano será excluido`,
        text: `Deseja excluir registro?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007744",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: `Sim, pode continuar`,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await axios.delete(
            `/api/v1/pet-primavera/plano/deletar/${id}`
          );

          if (res.data.status) {
            Swal.fire({
              title: "Exclusão realizada!",
              text: res.data.message,
              icon: "success",
              confirmButtonColor: "#007744",
            });
            this.setup();
          } else {
            Swal.fire({
              title: "Erro encontrado!",
              text: res.data.message,
              icon: "warning",
              confirmButtonColor: "#d33",
            });
          }
        }
      });
    },
    async loadPlanos(params) {
      this.carregamento = true;

      const planos = await axios.get("api/v1/pet-primavera/planos", {
        params: {
          page: params,
        },
      });

      if (planos.data.status) {
        this.planos = planos.data.planos.data;

        this.pagination = {
          page: planos.data.planos.page,
          perPage: planos.data.planos.perPage,
          lastPage: planos.data.planos.lastPage,
          total: planos.data.planos.total,
        };
        this.carregamento = false;
      }
    },
    editar(item) {
      this.dialog = !this.dialog;
      this.plano = item;
      this.titulo = item && item.id ? "Alterar Plano" : "Novo Plano";
    },
    salvar() {
      this.carregandoSave = true;

      let url = this.plano.id
        ? `/api/v1/pet-primavera/plano/alterar/${this.plano.id}`
        : "/api/v1/pet-primavera/plano/salvar";

      axios
        .post(url, this.plano)
        .then((res) => {
          if (res.data.status) {
            this.$toast(res.data.message, {
              type: "success",
            });

            this.clear();
            this.setup();

            this.carregandoSave = false;
          } else {
            this.$toast(res.data.message, {
              type: "error",
            });
            this.carregandoSave = false;
          }
        })
        .catch((res) => {
          if (res.response.data && res.response.data.validation) {
            this.error = res.response.data.validation;

            this.$toast(res.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(res.response.data.message, {
              type: "error",
            });
          }
          this.carregandoSave = false;
        });
    },
    clear() {
      this.plano = {
        limite_p: null,
        limite_m: null,
        limite_g: null,
        limite_gg: null,
        descricao: null,
        adicional_adesao_sem_resgate_p: null,
        adicional_adesao_com_resgate_p: null,
        adicional_mensalidade_com_resgate_p: null,
        adicional_mensalidade_sem_resgate_p: null,
        adicional_adesao_sem_resgate_m: null,
        adicional_adesao_com_resgate_m: null,
        adicional_mensalidade_com_resgate_m: null,
        adicional_mensalidade_sem_resgate_m: null,
        adicional_adesao_sem_resgate_g: null,
        adicional_adesao_com_resgate_g: null,
        adicional_mensalidade_com_resgate_g: null,
        adicional_mensalidade_sem_resgate_g: null,
        adicional_adesao_sem_resgate_gg: null,
        adicional_adesao_com_resgate_gg: null,
        adicional_mensalidade_com_resgate_gg: null,
        adicional_mensalidade_sem_resgate_gg: null,
        valor_adesao: null,
        carencia_plano: null,
        valor_mensalidade: null,
      };

      this.error = {
        limite_p: null,
        limite_m: null,
        limite_g: null,
        limite_gg: null,
        descricao: null,
        adicional_adesao_sem_resgate_p: null,
        adicional_adesao_com_resgate_p: null,
        adicional_mensalidade_com_resgate_p: null,
        adicional_mensalidade_sem_resgate_p: null,
        adicional_adesao_sem_resgate_m: null,
        adicional_adesao_com_resgate_m: null,
        adicional_mensalidade_com_resgate_m: null,
        adicional_mensalidade_sem_resgate_m: null,
        adicional_adesao_sem_resgate_g: null,
        adicional_adesao_com_resgate_g: null,
        adicional_mensalidade_com_resgate_g: null,
        adicional_mensalidade_sem_resgate_g: null,
        adicional_adesao_sem_resgate_gg: null,
        adicional_adesao_com_resgate_gg: null,
        adicional_mensalidade_com_resgate_gg: null,
        adicional_mensalidade_sem_resgate_gg: null,
        valor_adesao: null,
        carencia_plano: null,
        valor_mensalidade: null,
      };

      this.dialog = !this.dialog;
    },
    setup() {
      this.$store.dispatch("verifyToken");
      this.loadPlanos(this.pagination.page);
    },
  },

  mounted() {
    this.setup();
  },
};
</script>

<style scoped>


fieldset {
  padding: 16px;
}
</style>