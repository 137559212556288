<template>
  <div>
    <v-dialog v-model="dialogTimeline" max-width="700" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="#007744">
          <v-btn icon @click="dialogTimeline = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Timelines - Linha do tempo</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>

        <center v-if="carregamento" class="mt-10">
          <loading />
        </center>

        <center v-else-if="!carregamento && timelines.length === 0">
          <found />
        </center>
        <div v-else class="pa-10">
          <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item v-for="(item, i) in timelines" :key="i" color="#007744" :icon="item.icon" fill-dot>
              <v-card>
                <v-card-title class="title">{{ item.descricao }} </v-card-title>
                <v-card-text class="white text--primary">
                  <p>
                    <b>Alteração feita pelo usuário: {{ item.usuario }}</b>
                  </p>
                  <p class="warning--text">
                    <b>{{ item.acao }} um registro na {{ item.table_name }}</b>
                  </p>
                  <p>Realizado em: {{ dateFormat(item.created_at) }}</p>

                  <div v-if="item.object">
                    <p><b>Dados:</b></p>
                    <pre> {{ item.object }}</pre>
                  </div>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </div>
        <div class="text-left mt-5 pb-10">
          <v-pagination v-model="pagination.page" :length="pagination.lastPage" v-if="timelines.length > 0"
            :total-visible="pagination.perPage" color="#007744" @input="(page) => loadTimeline(page)"
            @next="() => loadTimeline(pagination.page)" @previous="() => loadTimeline(pagination.page)"></v-pagination>
        </div>
      </v-card>
    </v-dialog>
    <v-app v-if="$route.meta.allowAnonymous">
      <transition>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </v-app>

    <v-app v-else>
      <v-app-bar dense app color="rgb(0, 119, 68)" dark scroll-target="#scrolling-techniques-4">
        <v-container class="py-0 fill-height">
          <div class="d-flex align-center auto">
            <v-toolbar-title></v-toolbar-title>

            <v-spacer></v-spacer>

            <v-img class="mb-1" alt="Pet Primavera - WEB" contain src="img/logo.svg" width="40"
              @click="drawer = !drawer" />
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="openTimeline(pagination.page)">
            <v-icon color="#fff">mdi-chart-timeline-variant</v-icon>
          </v-btn>
          <v-menu bottom max-width="200" rounded offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon x-large v-on="on">
                <v-avatar color="#fff" class="black--text" size="35">
                  {{ namePerfil() }}
                </v-avatar>
              </v-btn>
            </template>
            <v-card>
              <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                  <v-avatar color="#007744" class="mb-2">
                    <span class="white--text headline">{{ namePerfil() }}</span>
                  </v-avatar>
                  <h6>{{ user.usuario }}</h6>
                  <p class="item-menu">
                    {{ user.email }}
                  </p>
                  <v-divider class="my-3"></v-divider>
                  <p text class="item-menu">
                    <b>Grupo acesso: {{ user.nome }}</b>
                  </p>
                  <v-divider class="my-3"></v-divider>
                  <v-btn text @click="sair()" color="error">
                    Finalizar Sessão
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-card>
          </v-menu>
        </v-container>
      </v-app-bar>
      <v-main class="grey lighten-3">
        <v-container>
          <v-row dense>
            <v-col cols="12" :md="drawer ? '3' : '12'" v-if="drawer">
              <v-sheet rounded="lg">
                <v-list dense color="transparent">
                  <v-subheader> Menus Acessos </v-subheader>
                  <v-list-item-group v-model="selectedItem" color="#007744">
                    <v-list-item v-for="(item, index) in menu.filter(item => !item.is_relatorio && !item.is_cadastro)" :key="index" link
                      @click="redirecionar(`${item.route}`)">
                      <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ item.nome }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                  <v-list-group color="#007744" prepend-icon="mdi-file-document" dense>
                    <template v-slot:activator>
                      <v-list-item-title>Relatórios</v-list-item-title>
                    </template>
                    <v-list-item link v-for="(item) in  menu.filter(item => item.is_relatorio)" :key="'#relatorio_' + item.id" @click="redirecionar(`${item.route}`)">
                      <v-list-item-title>{{ item.nome }}</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-group>
                  <v-list-group color="#007744" prepend-icon="mdi-plus" dense>
                    <template v-slot:activator>
                      <v-list-item-title>Cadastros</v-list-item-title>
                    </template>
                    <v-list-item link v-for="(item) in  menu.filter(item => item.is_cadastro)" :key="'#relatorio_' + item.id" @click="redirecionar(`${item.route}`)">
                      <v-list-item-title>{{ item.nome }}</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-sheet>
            </v-col>
            <v-col cols="12" :md="drawer ? '9' : '12'">
              <v-sheet rounded="lg" color="transparent">
                <router-view></router-view>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>


<script>
import Swal from "sweetalert2";
import axios from "../axios/service_private.js";

var moment = require("moment");
moment.locale("pt-br");

export default {
  name: "App",
  data: () => ({
    drawer: true,
    links: ["Dashboard", "Messages", "Profile", "Updates"],
    user: {},
    menu: [],
    selectedItem: 0,
    carregamento: false,
    timelines: [],
    dialogTimeline: false,
    pagination: {
      page: 1,
      perPage: 1,
      lastPage: 1,
    },
  }),
  methods: {
    dateFormat(param) {
      return moment(param).format("DD/MM/YYYY HH:mm");
    },
    openTimeline(params) {
      this.dialogTimeline = !this.dialogTimeline;
      this.loadTimeline(params);
    },
    async loadTimeline(params) {
      try {
        this.carregamento = true;

        const timelines = await axios.get(`/api/v1/pet-primavera/timelines`, {
          params: {
            page: params,
          },
        });

        if (timelines.data.status) {
          this.timelines = timelines.data.timelines.data;

          this.pagination = {
            page: timelines.data.timelines.page,
            perPage: timelines.data.timelines.perPage,
            lastPage: timelines.data.timelines.lastPage,
            total: timelines.data.timelines.total,
          };

          this.carregamento = false;
        }
      } catch (res) {
        if (res.response.data && res.response.data.validation) {
          this.error = res.response.data.validation;

          this.$toast(res.response.data.message, {
            type: "error",
          });
        } else {
          this.$toast(res.response.data.message, {
            type: "error",
          });
        }
        this.carregamento = false;
      }
    },
    redirecionar(route) {
      this.$router.replace({ path: route });
    },
    namePerfil() {
      if (this.user && !this.user.usuario) return "PP";
      return (
        this.user.usuario[0] + this.user.usuario[this.user.usuario.length - 1]
      ).toUpperCase();
    },
    sair() {
      Swal.fire({
        title: `Finalizar Sessão!`,
        text: `Deseja encerra seu acesso ao sistema?`,
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#007744",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: `Sim, pode finalizar`,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$router.go();
          window.localStorage.clear();
        }
      });
    },
  },
  mounted() {
    let usuario = JSON.parse(localStorage.getItem("user"));

    this.menu = JSON.parse(localStorage.getItem("menu_lateral_sistema_pet"));
    this.user = usuario;
  },
};
</script>
