<template>
  <div>
    <div>
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title><b>Nova Permissão</b>
            <v-switch v-model="permissao.is_menu" label="Item Menu" class="pl-3 pt-1"
              color="#007744"></v-switch></v-card-title>
          <v-divider></v-divider>
          <v-form ref="form" class="pa-8 mx-auto">
            <v-row dense>
              <v-col cols="12" md="12">
                <v-text-field color="#007744" v-model="permissao.nome" :error-messages="error.nome"
                  label="Nome permissão:" required outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field color="#007744" v-model="permissao.ordem" :error-messages="error.ordem" label="Ordem"
                  required outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field color="#007744" v-model="permissao.icon" :error-messages="error.icon"
                  label="Selecione o icone:" required outlined dense></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field color="#007744" v-model="permissao.route" :error-messages="error.route"
                  label="Link da página:" required outlined dense></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field color="#007744" v-model="permissao.route_api" :error-messages="error.route_api"
                  label="Link da Api:" required outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-switch v-model="permissao.is_cadastro" label="Item pertence ao menu de cadastro?" class="pl-3 pt-1"
                  color="#007744"></v-switch>
              </v-col>
              <v-col cols="12" md="6">
                <v-switch v-model="permissao.is_relatorio" label="Item pertence ao menu de relatórios?" class="pl-3 pt-1"
                  color="#007744"></v-switch>
              </v-col>
            </v-row>

            <v-btn color="#007744" class="mr-4 mt-3 white--text" @click="salvar" :disabled="carregamentoSave"
              :loading="carregamentoSave">
              <v-icon dark> mdi-check </v-icon>
              Salvar
            </v-btn>

            <v-btn color="error" class="mr-4 mt-3" @click="clear">
              <v-icon dark> mdi-close </v-icon>
            </v-btn>
          </v-form>
        </v-card>
      </v-dialog>

      <loading v-if="carregamento" />

      <v-card v-else flat>
        <v-card-title>
          <b class="titulo-header-page">Permissões</b>
          <v-spacer></v-spacer>
          <v-btn color="#007744" small class="white--text" @click="clear">
            <v-icon dark> mdi-plus </v-icon>
            Nova Permissão
          </v-btn>
        </v-card-title>

        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Nome</th>
                <th class="text-left">Icon</th>
                <th class="text-left">Menu</th>
                <th class="text-left">Página</th>
                <th class="text-left">Api</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in permissoes" :key="index">
                <td class="text-left">{{ item.id }}</td>
                <td class="text-left">{{ item.nome }}</td>
                <td class="text-left">
                  <v-icon>{{ item.icon }}</v-icon>
                </td>
                <td class="text-left">
                  <v-icon color="success" v-if="item.is_menu">
                    mdi-check
                  </v-icon>

                  <v-icon color="error" v-else> mdi-close </v-icon>
                </td>
                <td class="text-left">
                  {{ item.route }}
                </td>
                <td class="text-left">
                  {{ item.route_api }}
                </td>
                <td class="text-left">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon v-bind="attrs" v-on="on" color="black">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="editar(item)">
                        <v-list-item-title>Alterar informações</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="excluir(item.id)">
                        <v-list-item-title>Excluir permissão</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
    <div class="text-left mt-5">
      <v-pagination v-model="pagination.page" :length="pagination.lastPage" :total-visible="pagination.perPage"
        color="#007744" v-if="permissoes.length > 0" @input="(page) => loadPermissoes(page)"
        @next="() => loadPermissoes(pagination.page)" @previous="() => loadPermissoes(pagination.page)"></v-pagination>
    </div>
  </div>
</template>
<script>
import axios from "../../axios/service_private.js";
import Swal from "sweetalert2";

var moment = require("moment");
moment.locale("pt-br");

export default {
  data() {
    return {
      dialog: false,
      carregamento: false,
      carregamentoSave: false,
      permissao: {
        nome: null,
        icon: null,
        route_api: null,
        route: null,
        is_menu: false,
        ordem: 0,
        is_relatorio: false,
        is_cadastro: false
      },
      error: {
        nome: null,
        icon: null,
        route_api: null,
        route: null,
        is_menu: false,
        ordem: 0,
      },
      permissoes: [],
      grupos: [],
      pagination: {
        page: 1,
        perPage: 1,
        lastPage: 1,
      },
    };
  },
  methods: {
    excluir(id) {
      Swal.fire({
        title: `Permissão será excluido`,
        text: `Deseja excluir registro?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007744",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: `Sim, pode continuar`,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await axios.delete(
            `/api/v1/pet-primavera/permissao/deletar/${id}`
          );

          if (res.data.status) {
            Swal.fire({
              title: "Exclusão realizada!",
              text: res.data.message,
              icon: "success",
              confirmButtonColor: "#007744",
            });
            this.setup();
          } else {
            Swal.fire({
              title: "Erro encontrado!",
              text: res.data.message,
              icon: "warning",
              confirmButtonColor: "#d33",
            });
          }
        }
      });
    },
    editar(item) {
      this.dialog = !this.dialog;
      this.permissao = item;
    },
    clear() {
      this.permissao = {
        nome: null,
        icon: null,
        route_api: null,
        route: null,
        ordem: 0,
        is_relatorio: false,
        is_cadastro: false
      };

      this.error = {
        nome: null,
        icon: null,
        route_api: null,
        route: null,
        ordem: 0,
      };

      this.dialog = !this.dialog;
    },
    salvar() {
      this.carregandoSave = true;

      let url = this.permissao.id
        ? `/api/v1/pet-primavera/permissao/alterar/${this.permissao.id}`
        : "/api/v1/pet-primavera/permissao/salvar";

      axios
        .post(url, this.permissao)
        .then((res) => {
          if (res.data.status) {
            this.$toast(res.data.message, {
              type: "success",
            });

            this.clear();
            this.setup();

            this.carregandoSave = false;
          } else {
            this.$toast(res.data.message, {
              type: "error",
            });
            this.carregandoSave = false;
          }
        })
        .catch((res) => {
          if (res.response.data && res.response.data.validation) {
            this.error = res.response.data.validation;

            this.$toast(res.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(res.response.data.message, {
              type: "error",
            });
          }
          this.carregandoSave = false;
        });
    },
    dateFormat(param) {
      return moment(param).format("DD/MM/YYYY HH:mm");
    },
    async loadPermissoes(params) {
      this.carregamento = true;

      const grupos = await axios.get("api/v1/pet-primavera/permissoes", {
        params: {
          page: params,
        },
      });

      if (grupos.data.status) {
        this.permissoes = grupos.data.permissoes.data;

        this.pagination = {
          page: grupos.data.permissoes.page,
          perPage: grupos.data.permissoes.perPage,
          lastPage: grupos.data.permissoes.lastPage,
          total: grupos.data.permissoes.total,
        };

        this.carregamento = false;
      }
    },
    setup() {
      this.$store.dispatch("verifyToken");
      this.loadPermissoes(this.pagination.page);
    },
  },
  mounted() {
    this.setup();
  },
};
</script>