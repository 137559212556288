<template>
  <div class="home">
    <loading v-if="carregamento" />
    <sistema :message="message" v-else-if="painel && painel.length <= 0" />
    <div v-else>
      <div class="titulo">
        <h1>Gerencial</h1>
      </div>
      <v-row dense>
        <v-col cols="12" md="4" v-for="(item, index) in painel" :key="index">
          <widget
            icon="mdi-paw"
            :subTitle="item.nome"
            color="blue darken-4"
            :title="String(item.total)"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "../../axios/service_private.js";

export default {
  name: "Home",
  data: () => ({
    message: localStorage.getItem("message"),
    carregamento: false,
    painel: [],
  }),
  methods: {
    async painelGerencial() {
      try {
        this.carregamento = true;

        const painel = await axios.get("api/v1/pet-primavera/relatorio/geral");

        if (painel.data.status) {
          this.painel = painel.data.relatorio;
        }

        this.carregamento = false;
      } catch (error) {
         this.carregamento = false;
      }
    },
    setup() {
      this.painelGerencial();
      this.$store.dispatch("verifyToken");
    },
  },
  mounted() {
    this.setup();
  },
};
</script>


<style scoped>
.titulo {
  padding: 8px 8px;
  margin-top: 8px;
  margin-bottom: 0.5rem;
  border-left: 4px solid #007744;
  border-radius: 5px;
}
.titulo h1 {
  font-size: 1.2rem;
}
</style>