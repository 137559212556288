<template>
    <div>
        <v-card>
            <v-card-title class="mx-auto">
                <strong class="titulo-stepper">{{ titulo }}</strong>
            </v-card-title>

            <v-divider></v-divider>

            <v-form ref="form" class="pa-8 mx-auto">
                <fieldset class="pa-4 mb-4">
                    <legend>Endereço do Contratante</legend>

                    <v-row dense>
                        <v-col cols="12" md="6" v-if="tipoContrato(campos.logradouro)">
                            <v-text-field
                                color="#007744"
                                v-model="endereco.logradouro"
                                :error-messages="error.logradouro"
                                label="Logradouro:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.numero)">
                            <v-text-field
                                color="#007744"
                                v-model="endereco.numero"
                                :error-messages="error.numero"
                                label="Número:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.bairro)">
                            <v-text-field
                                color="#007744"
                                v-model="endereco.bairro"
                                :error-messages="error.bairro"
                                label="Bairro:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.estado)">
                            <v-autocomplete
                                item-value="id"
                                item-text="nome"
                                label="Estado:"
                                :error-messages="error.estado"
                                color="#007744"
                                :items="estados"
                                type="text"
                                auto-select-first
                                outlined
                                dense
                                chips
                                clearable
                                deletable-chips
                                @input="(value) => loadAndDisable(value)"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.cidade)">
                            <v-autocomplete
                                class="mb-5"
                                item-value="id"
                                item-text="nome"
                                label="Cidade:"
                                color="#007744"
                                :items="cidades"
                                :disabled="cidades.length <= 0"
                                type="text"
                                auto-select-first
                                outlined
                                dense
                                chips
                                clearable
                                deletable-chips
                                :error-messages="error.cidade"
                                @input="(value) => defineCidadeId(value)"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.cep)">
                            <v-text-field
                                color="#007744"
                                v-model="endereco.cep"
                                :error-messages="error.cep"
                                label="CEP:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.obs)">
                            <v-text-field
                                color="#007744"
                                v-model="endereco.obs"
                                :error-messages="error.obs"
                                label="OBS:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </fieldset>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import axios from "../../../../axios/service_private";

export default {
    name: "contratanteEndereco",
    data: () => ({
        endereco: {
            id: null,
            logradouro: null,
            numero: null, // Não esquecer de converter para string antes de enviar
            bairro: null,
            estado: null,
            cidade: null,
            cep: null,
            obs: null,
            cidade_id: null
        },
        error: {
            logradouro: null,
            numero: null,
            bairro: null,
            estado: null,
            cidade: null,
            cep: null,
            obs: null
        },
        dadosContratante: {},
        botao_liberado: false,
        estados: [],
        cidades: []
    }),
    props: {
        campos: {
            type: Object
        },
        titulo: {
            type: String
        },
        ordem: {
            type: Number
        },
        infoCliente: {
            type: Object
        }
    },
    methods: {
        emitDados() {
            this.$emit('dadosEnderecoContratante', this.endereco);
        },
        tipoContrato(array) {
            return array.includes(this.ordem);
        },
        preencherCampos() {
            if (this.infoCliente.rua_endereco) {
                this.endereco.id = this.infoCliente.id;
                this.endereco.logradouro = this.infoCliente.rua_endereco;
                this.endereco.numero = this.infoCliente.numero_lote_endereco;
                this.endereco.bairro = this.infoCliente.bairro_endereco;
                this.endereco.estado = this.infoCliente.estado;
                this.endereco.cidade = this.infoCliente.cidade;
            } else {
                this.endereco.id = this.infoCliente.id_endereco_table;
                this.endereco.logradouro = this.infoCliente.logradouro;
                this.endereco.numero = this.infoCliente.numero;
                this.endereco.bairro = this.infoCliente.bairro;
                this.endereco.estado = this.infoCliente.estado;
                this.endereco.cidade = this.infoCliente.cidade;
                this.endereco.cep = this.infoCliente.cep;
                this.endereco.obs = this.infoCliente.obs;
            }
        },
        liberarBotao() {
            if (this.titulo === 'Contrato PET Primavera - Particular' || this.titulo === 'Contrato PET Primavera - Particular - Multiplos pets') {
                if (this.endereco.logradouro !== null &&
                    this.endereco.bairro !== null &&
                    this.endereco.estado !== null &&
                    this.endereco.cidade !== null &&
                    this.endereco.cep !== null &&
                    this.endereco.obs !== null
                ) {
                    this.botao_liberado = true;
                }
            }

            if (this.titulo === 'Contrato PET Primavera - Dourados - Plano PET individualizado') {
                if (this.endereco.logradouro !== null &&
                    this.endereco.numero !== null &&
                    this.endereco.bairro !== null &&
                    this.endereco.estado !== null &&
                    this.endereco.cidade !== null &&
                    this.endereco.cep !== null &&
                    this.endereco.obs !== null
                ) {
                    this.botao_liberado = true;
                }
            }

            if (this.titulo === 'PET PRIMAVERA - DECLARAÇÃO DE CANCELAMENTO DO PLANO PARTICULAR') {
                if (this.endereco.logradouro !== null &&
                    this.endereco.cidade !== null
                ) {
                    this.botao_liberado = true;
                }
            }

            if (this.titulo === 'TABLET VENDAS - TERMO DE INCLUSÃO DE DEPENDENTE PET PRIMAVERA ASSOCIADO' ||
                this.titulo === 'PET PRIMAVERA - DECLARAÇÃO DE EXCLUSÃO DE DEPENDENTE PET ASSOCIADO' ||
                this.titulo === 'PET PRIMAVERA - DECLARAÇÃO DE EXCLUSÃO DE DEPENDENTE PET PARTICULAR' ||
                this.titulo === 'PET PRIMAVERA - DECLARAÇÃO DE INCLUSÃO DE DEPENDENTE PET PARTICULAR'
            ) {
                if (this.endereco.logradouro !== null &&
                    this.endereco.numero !== null &&
                    this.endereco.bairro !== null &&
                    this.endereco.estado !== null &&
                    this.endereco.cidade !== null &&
                    this.endereco.cep !== null &&
                    this.endereco.obs !== null
                ) {
                    this.botao_liberado = true;
                }
            }
            
        },
        getEstados() {
            axios
            .get("/api/v1/pet-primavera/estados-sem-pagination")
                .then((response) => {
                    if (response.data.status) {
                        this.estados = response.data.estados;
                    }
                });
        },
        getCidades(id) {
            axios
                .get(`/api/v1/pet-primavera/cidades-selecionar-estado/${id}`)
                    .then((response) => {
                        if (response.data.status) {
                            this.cidades = response.data.cidades;
                        }
                    });
        },
        loadAndDisable(value) {
            this.defineEstado(value);
            this.getCidades(value);
            this.liberarBotao();
        },
        defineCidadeId(value) {
            this.cidades.forEach(cidade => {
                if (cidade.id === value) {
                    this.endereco.cidade = cidade.nome;
                    this.endereco.cidade_id = cidade.id;
                }
            });
        },
        defineEstado(value) {
            this.estados.forEach(estado => {
                if (estado.id === value) {
                    this.endereco.estado = estado.nome;
                }
            });
        }
    },
    watch: {
        infoCliente(value) {
            if (value !== null && value !== undefined) this.preencherCampos();
        },

        endereco: {
            handler() {
                this.$emit('atualizar-botao-liberado-endereco', this.botao_liberado);
            },
            deep: true
        }
    },
    mounted() {
        this.getEstados();
    }
};
</script>

<style scoped>

.titulo-stepper {
    color: #007744;
    margin: auto;
}

</style>