<template>
  <v-app id="login" class="fundo">
    <section class="login">
      <div class="form-login">
        <center v-if="carregamentoTela">
          <loginLoading :message="message" />
        </center>

        <div v-else>
          <div class="layout column align-center pa-10">
            <v-img
              alt="Pax Primavera - Pet"
              class="shrink"
              contain
              src="img/logo.png"
              transition="scale-transition"
              width="100"
            />
          </div>
          <v-form
            method="post"
            class="formLogin"
            ref="form"
            @submit.prevent="logar"
          >
            <v-text-field
              label="CPF:"
              color="#007744"
              v-model="login.cpf"
              outlined
              dense
              v-mask="'###.###.###-##'"
              :disabled="carregando"
              required
              :error-messages="error.cpf"
              autocomplete="off"
            ></v-text-field>

            <v-text-field
              label="Senha"
              type="password"
              color="#007744"
              outlined
              dense
              v-model="login.senha"
              :disabled="carregando"
              :error-messages="error.senha"
              required
            ></v-text-field>

            <v-btn
              color="#007744"
              type="submit"
              class="mr-4 mb-10 white--text"
              block
              :disabled="!login.cpf || !login.senha || carregando"
              :loading="carregando"
              @click="logar"
            >
              Entrar
            </v-btn>
          </v-form>
        </div>
        <div class="versao-sistema">
          Pet Primavera:
          <a class="link"> {{ pack.version }} </a>
        </div>
      </div>
    </section>
  </v-app>
</template>

<script>
import axios from "../../axios/service_public.js";

export default {
  name: "login",
  data: () => ({
    pack: require("../../package.json"),
    carregando: false,
    carregamentoTela: true,
    message: null,
    login: {
      cpf: null,
      senha: null,
    },
    error: {
      cpf: null,
      senha: null,
    },
  }),
  created() {
    setTimeout(() => {
      this.carregamentoTela = false;
    }, 1000);
  },
  methods: {
    redirecionar(route) {
      this.$router.push({ path: route }, () => {});
    },
    clear() {
      this.error = {
        cpf: null,
        senha: null,
      };
    },
    logar() {
      /// Limpar LocalStorage
      localStorage.clear();

      this.carregando = true;
      this.carregamentoTela = true;
      this.message = "Aguarde, estamos verificando seus dados.";

      axios
        .post("/api/v1/auth/entrar", this.login)
        .then((res) => {
          if (res.data.status) {
            try {
              window.localStorage.setItem(
                "token_web_pet_primavera",
                res.data.token
              );
              window.localStorage.setItem(
                "user",
                JSON.stringify(res.data.usuario)
              );
              window.localStorage.setItem(
                "menu_lateral_sistema_pet",
                JSON.stringify(res.data.menu)
              );
              let redirect = setInterval(() => {
                let menu = JSON.parse(
                  localStorage.getItem("menu_lateral_sistema_pet")
                );

                if (menu && menu.length > 0) {
                  this.$router.push({ path: menu[0].route });
                } else {
                  this.$router.push({ path: "/" });
                }

                this.$router.go();
                this.clear();

                this.carregando = false;
                this.carregamentoTela = res.data.status;
                this.message = `Seja bem vindo, ${res.data.usuario.usuario}!`;

                window.localStorage.setItem("message", this.message);

                clearInterval(redirect);
              }, 1000);
            } catch (e) {
              return false;
            }
          }
        })
        .catch((res) => {
          if (res.response.data && res.response.data.validation) {
            this.error = res.response.data.validation;
            this.$toast("Não foi possivel acessar o sistema, acesso negado!", {
              type: "error",
            });
          } else {
            this.$toast(res.response.data.message, {
              type: "error",
            });
            this.error = {};
          }

          this.carregando = false;
          this.carregamentoTela = false;
        });
    },
  },
};
</script>

<style scoped lang="css">
#login {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}

.form-login {
  width: 100%;
  height: 100vh;
  background: rgba(255,255,255,0.7);
  padding: 20px 20px;
}

.fundo {
  background-image: url("../../public/img/fundo_login.png");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.link {
  color: #007744;
  text-decoration: none;
  font-weight: bold;
}

.versao-sistema {
  text-align: center;
  font-weight: bold;
}

@media screen and (min-width:720px) {
  .form-login {
    width: 30%;
      background: #fff;
  }
}
</style>
