<template>
  <a @click="() => redirecionar(link)">
    <v-card class="widget-card" elevation="3">
      <v-card-text class="pa-1">
        <center class="corPadrao pa-10" v-if="!title">
          <v-progress-circular
            :size="30"
            class="margin"
            color="white"
            indeterminate
          ></v-progress-circular>
        </center>
        <v-container class="pa-0" v-else>
          <div class="layout row ma-0 corPadrao">
            <div class="sm4 xs4 flex">
              <div
                class="layout pl-1 pr-1 column ma-4 justify-center align-center"
              >
                <v-icon size="30px" color="#007744" style="opacity: 0.8">{{
                  icon
                }}</v-icon>
              </div>
            </div>
            <div
              class="layout column ma-0 justify-center"
              style="color: #007744"
            >
              <span class="caption">{{ subTitle }}</span>
              <div class="headline">{{ title }}</div>
            </div>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </a>
</template>

<script>
export default {
  props: {
    supTitle: {
      type: String,
      required: false,
    },
    subTitle: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    redirecionar(route) {
      this.$router.push({ path: route }, () => {});
    },
  },
};
</script>

<style scoped>
.widget-card {
  border-radius: 2%;
  background-color: transparent;
  width: 100%;
}
.corPadrao {
  background: #fff;
}
</style>