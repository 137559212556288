<template>
  <div>
    <!-- modal cadastro -->
    <v-dialog
      persistent
      v-model="dialog"
      max-width="600"
      class="dialogRelatorio"
    >
      <v-card>
        <v-card-title class="titleRelatorio">
          <b>{{ usuario && usuario.id ? "Alterar" : "Novo" }} Usuário</b>
        </v-card-title>
        <v-divider></v-divider>

        <v-form ref="form" class="pa-8 mx-auto">
          <div class="d-flex">
            <v-text-field
              class="mb-5 mr-2"
              label="Nome"
              color="#007744"
              v-model="usuario.nome"
              required
              outlined
              dense
              :error-messages="error.nome"
            ></v-text-field>

            <v-text-field
              class="mb-5 ml-2"
              label="Email"
              color="#007744"
              v-model="usuario.email"
              required
              outlined
              dense
              :error-messages="error.email"
            ></v-text-field>
          </div>

          <div class="d-flex">
            <v-text-field
              class="mb-2 mr-2"
              label="CPF"
              color="#007744"
              v-mask="'###.###.###-##'"
              required
              outlined
              dense
              v-model="usuario.cpf"
              :error-messages="error.cpf"
            ></v-text-field>

            <v-text-field
              class="mb-2 ml-2"
              label="Senha"
              color="#007744"
              required
              type="password"
              v-model="usuario.senha"
              outlined
              dense
              :error-messages="error.senha"
            ></v-text-field>
          </div>

          <v-autocomplete
            class="mb-5"
            item-value="id"
            item-text="nome"
            label="Grupo de Permissões"
            color="#007744"
            :items="grupos"
            outlined
            dense
            v-model="usuario.grupo_acesso_id"
            auto-select-first
            chips
            clearable
            deletable-chips
            :error-messages="error.grupo_acesso_id"
          ></v-autocomplete>

          <v-btn
            color="#007744"
            class="mr-4 white--text"
            dense
            @click="cadastrarUsuario()"
            :loading="carregandoSave"
          >
            <v-icon dark> mdi-check </v-icon>
            Salvar
          </v-btn>

          <v-btn color="error" class="mr-4" @click="clear()">
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Modal final -->
    <loading v-if="carregamento" />

    <v-card v-else flat>
      <v-card-title>
        <h2 class="titulo-header-page">Usuários</h2>
        <v-spacer></v-spacer>
        <v-btn
          elevation="1"
          color="#007744"
          class="white--text"
          small
          @click="clear()"
        >
          Novo Usuário
        </v-btn>
      </v-card-title>

      <template>
        <v-simple-table dense>
          <template>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Nome</th>
                <th class="text-left">Email</th>
                <th class="text-center">Grupo de acesso</th>
                <th class="text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(usuario, index) in usuarios"
                :key="index"
                :class="usuario.is_ativo ? '' : 'red lighten-1'"
              >
                <td class="text-left">{{ usuario.id }}</td>
                <td class="text-left">{{ usuario.nome }}</td>
                <td class="text-left">{{ usuario.email }}</td>
                <td class="text-center">
                  <v-chip label small outlined color="#007744">
                    {{ usuario.grupo }}
                  </v-chip>
                </td>
                <td class="text-center">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon v-bind="attrs" v-on="on" color="black">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="editarUsuario(usuario)">
                        <v-list-item-title>Alterar Usuario</v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="desativarUsuario(usuario)">
                        <v-list-item-title
                          >{{
                            usuario.is_ativo ? "Desativar" : "Ativar"
                          }}
                          Usuário</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </v-card>

    <div class="text-left mt-5">
      <v-pagination
        v-model="pagination.page"
        :length="pagination.lastPage"
        :total-visible="pagination.perPage"
        color="#007744"
        v-if="usuarios.length > 0"
        @input="(page) => loadUsuarios(page)"
        @next="() => loadUsuarios(pagination.page)"
        @previous="() => loadUsuarios(pagination.page)"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import axios from "../../axios/service_private.js";
import Swal from "sweetalert2";

export default {
  name: "Usuarios",
  data: () => {
    return {
      dialog: false,
      carregandoSave: false,
      carregamento: false,
      usuarios: [],
      grupos: [],
      usuarioID: null,
      usuario: {
        nome: null,
        email: null,
        cpf: null,
        grupo_acesso_id: null,
        senha: null,
      },
      error: {
        nome: null,
        email: null,
        cpf: null,
        grupo_id: null,
        senha: null,
      },
      pagination: {
        page: 1,
        perPage: 1,
        lastPage: 1,
      },
    };
  },
  methods: {
    clear() {
      (this.usuario = {
        nome: null,
        email: null,
        cpf: null,
        grupo_acesso_id: null,
        senha: null,
      }),
        (this.error = {
          nome: null,
          email: null,
          cpf: null,
          grupo_id: null,
          senha: null,
        }),
        (this.dialog = !this.dialog);
    },
    loadGrupos() {
      axios.get("/api/v1/pet-primavera/grupos-acessos").then((response) => {
        if (response.data.status) {
          this.grupos = response.data.grupos;
        }
      });
    },
    cadastrarUsuario() {
      this.carregandoSave = true;

      let url = this.usuario.id
        ? "/api/v1/pet-primavera/usuario/alterar/" + this.usuario.id
        : "/api/v1/pet-primavera/usuario/salvar";

      axios
        .post(url, {
          nome: this.usuario.nome,
          senha: this.usuario.senha,
          email: this.usuario.email,
          cpf: this.usuario.cpf,
          grupo_acesso_id: this.usuario.grupo_acesso_id,
        })

        .then((response) => {
          if (response.data.status) {
            this.$toast(response.data.message, {
              type: "success",
            });
          }

          this.carregandoSave = !this.carregandoSave;
          this.loadUsuarios();
          this.clear();
        })

        .catch((error) => {
          if (error.response.data && error.response.data.validation) {
            this.error = error.response.data.validation;

            this.$toast(error.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(error.response.data.message, {
              type: "error",
            });
          }
          this.carregandoSave = false;
        });
    },
    editarUsuario(usuario) {
      this.dialog = !this.dialog;
      this.usuario = usuario;
      this.usuario.senha = null;
    },
    desativarUsuario(usuario) {
      Swal.fire({
        title: !usuario.is_ativo
          ? "O usuário será ativado!"
          : "O usuário será desativado!",
        text: `Algumas ações são irreversíveis. Tem certeza que deseja ${
          usuario.is_ativo ? "ativar" : "desativar"
        } o usuário?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#007744",
        cancelButtonText: "Cancelar",
        confirmButtonText: !usuario.is_ativo ? "Ativar" : "Desativar",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let url = !usuario.is_ativo
            ? "/api/v1/pet-primavera/usuario/ativar/" + usuario.id
            : "/api/v1/pet-primavera/usuario/desativar/" + usuario.id;
          let res = await axios.post(url);
          if (res.data.status) {
            Swal.fire({
              title: !usuario.is_ativo
                ? "O usuário foi ativado!"
                : "O usuário foi desativado!",
              text: res.data.message,
              icon: "success",
              confirmButtonColor: "#007744",
            });
            this.loadUsuarios();
          } else {
            Swal.fire({
              title: "Houve um erro!",
              text: res.data.message,
              icon: "warning",
              confirmButtonColor: "#d33",
            });
          }
        }
      });
    },
    async loadUsuarios(params) {
      this.carregamento = true;

      const usuarios = await axios.get("api/v1/pet-primavera/usuarios", {
        params: {
          page: params,
        },
      });

      if (usuarios.data.status) {
        this.usuarios = usuarios.data.usuarios.data;

        this.pagination = {
          page: usuarios.data.usuarios.page,
          perPage: usuarios.data.usuarios.perPage,
          lastPage: usuarios.data.usuarios.lastPage,
          total: usuarios.data.usuarios.total,
        };

        this.carregamento = false;
      }
    },
  },
  mounted() {
    this.loadUsuarios(this.pagination.page);
    this.loadGrupos();
    this.$store.dispatch("verifyToken");
  },
};
</script>

<style scoped>
.dialogRelatorio::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>