<template>
  <v-row dense>
    <v-col cols="12" md="12">
      <loading v-if="carregamento" />
      <v-card v-else class="pb-4" flat>
        <v-card-title>
          <b class="titulo-header-page">Novos Contratos</b>
        </v-card-title>
        <div class="ml-5 mr-5">
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field
                class="input"
                color="#007744"
                label="Data inicial:"
                v-model="params.inicio"
                required
                type="date"
                outlined
                dense>

              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                class="input"
                v-model="params.fim"
                @input="() => this.loadRelatorio()"
                color="#007744"
                type="date"
                label="Data final:"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <found v-if="relatorio && relatorio.length <= 0" />
        <v-simple-table dense v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">Contrato</th>
                <th class="text-left">Plano</th>
                <th class="text-left">Titular</th>
                <th class="text-left">CPF/CNPJ</th>
                <th class="text-left">Cidade</th>
                <th class="text-left">Estado</th>
                <th class="text-left">Primeira mensalidade</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in relatorio" :key="index">
                <td class="text-center">{{ item.contrato }}</td>
                <td class="text-left">{{ item.descricao }}</td>
                <td class="text-left">{{ item.nome_titular }}</td>
                <td class="text-left">{{ item.cpf_cnpj }}</td>
                <td class="text-left">{{ item.cidade_titular }}</td>
                <td class="text-left">{{ item.estado_titular }}</td>
                <td class="text-left">
                  {{ dateFormat(item.data_primeira_mensalidade) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>
  <script>
import axios from "../../axios/service_private.js";

var moment = require("moment");
moment.locale("pt-br");

export default {
  data() {
    return {
      carregamento: false,
      relatorio: [],
      params: {
        inicio: null,
        fim: null,
      },
    };
  },
  methods: {
    dateFormat(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    async loadRelatorio() {
      this.relatorio = [];
      this.carregamento = true;

      const relatorio = await axios.get(
        "api/v1/pet-primavera/relatorio/novos-contratos",
        {
          params: this.params,
        }
      );

      if (relatorio.data.status) {
        this.relatorio = relatorio.data.contratos;
        this.carregamento = false;
      }
    },
    setup() {
      this.$store.dispatch("login/verifyToken");
      this.loadRelatorio();
    },
  },
  mounted() {
    this.setup();
  },
};
</script>
  
  <style scoped>
.link {
  color: #007744;
}
</style>