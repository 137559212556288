<template>
    <div>
        <v-card>
            <v-card-title class="mx-auto">
                <strong class="titulo-stepper">{{ titulo }}</strong>
            </v-card-title>

            <v-divider></v-divider>

            <v-form ref="form" class="pa-8 mx-auto">

                <v-row dense>
                    <v-col cols="12" md="6" v-if="[2, 4, 5, 6, 7, 8].includes(ordem)">
                        <v-autocomplete
                            v-model="tipo_plano"
                            item-value="descricao"
                            item-text="descricao"
                            label="Plano:"
                            color="#007744"
                            :items="planos"
                            prepend-icon="mdi-form-select"
                            auto-select-first
                            outlined
                            dense
                            chips
                            clearable
                            deletable-chips
                            @change="fillFields"
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <fieldset class="pa-4 mb-4">
                    <legend>Taxa de Adesão:</legend>

                    <v-row dense>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.valor_adesao)">
                            <v-text-field
                                color="#007744"
                                v-model="adesao.valor_adesao"
                                :error-messages="error.valor_adesao"
                                label="Valor da Adesão:"
                                type="number"
                                aria-required
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.valor_adesao_extenso)">
                            <v-text-field
                                color="#007744"
                                v-model="adesao.valor_adesao_extenso"
                                :error-messages="error.valor_adesao_extenso"
                                label="Valor da Adesão por Extenso:"
                                type="text"
                                aria-required
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.valor_adesao_adicionais)">
                            <v-text-field
                                color="#007744"
                                v-model="adesao.valor_adesao_adicionais"
                                :error-messages="error.valor_adesao_adicionais"
                                label="Valor Adesão Adicionais:"
                                type="number"
                                aria-required
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.valor_adesao_adicionais_extenso)">
                            <v-text-field
                                color="#007744"
                                v-model="adesao.valor_adesao_adicionais_extenso"
                                :error-messages="error.valor_adesao_adicionais_extenso"
                                label="Valor Adesão Adicionais por Extenso:"
                                type="text"
                                aria-required
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.valor_total_adesao)">
                            <v-text-field
                                color="#007744"
                                v-model="adesao.valor_total_adesao"
                                :error-messages="error.valor_total_adesao"
                                label="Valor Total Adesão:"
                                type="number"
                                aria-required
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.valor_total_adesao_extenso)">
                            <v-text-field
                                color="#007744"
                                v-model="adesao.valor_total_adesao_extenso"
                                :error-messages="error.valor_total_adesao_extenso"
                                label="Valor Total Adesão por Extenso:"
                                type="text"
                                aria-required
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </fieldset>
            </v-form>

        </v-card>
    </div>
</template>

<script>
import axios from '../../../../axios/service_private';

export default {
    name: 'adesao',
    data: () => ({
        planos: [],
        tipo_plano: null,
        adesao: {
            id: null,
            valor_adesao: null,
            valor_adesao_extenso: null,
            valor_adesao_adicionais: null,
            valor_adesao_adicionais_extenso: null,
            valor_total_adesao: null,
            valor_total_adesao_extenso: null,
            plano_id: null
        },
        error: {
            valor_adesao: null,
            valor_adesao_extenso: null,
            valor_adesao_adicionais: null,
            valor_adesao_adicionais_extenso: null,
            valor_total_adesao: null,
            valor_total_adesao_extenso: null
        },
        botao_liberado: false,
        adesoes: 0
    }),
    props: {
        campos: {
            type: Object
        },
        titulo: {
            type: String
        },
        ordem: {
            type: Number
        },
        infoCliente: {
            type: Object
        },
        dadosPets: {
            type: Array,
            default: []
        },
        jaCadastrado: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        emitDados() {
            this.$emit('dadosAdesao', this.converte());
        },
        converte() {
            this.adesao.valor_adesao = Number(this.adesao.valor_adesao);
            this.adesao.valor_adesao_adicionais = Number(this.adesao.valor_adesao_adicionais);
            this.adesao.valor_total_adesao = Number(this.adesao.valor_total_adesao);
            return this.adesao;
        },
        tipoContrato(array) {
            return array.includes(this.ordem);
        },
        async getPlanos() {
            const response = await axios.get('api/v1/pet-primavera/planos-sem-pagination');
            if (response.data.status) {
                this.planos = response.data.planos;
            }
        },
        fillFields(selectedItem) {
            const selectedPlano = this.planos.find(plano => plano.descricao === selectedItem);
            if (selectedPlano) {
                this.adesao.plano_id = selectedPlano.id;
                this.calcularAdesoes(selectedPlano);
            }
            
        },
        buscaPlano(id) {
            const plano = this.planos.find(plano => plano.id === id);
            return plano.descricao;
        },
        preencherCampos() {
            if (this.jaCadastrado[4]) {
                if (!this.infoCliente.contratante_id_adesao) {
                    this.adesao.valor_adesao = this.infoCliente.v_adesao;
                } else {
                    this.tipo_plano = this.buscaPlano(this.infoCliente.plano_id);
                    this.adesao.id = this.infoCliente.id_adesao_table;
                    this.adesao.valor_adesao = this.infoCliente.valor_adesao;
                    this.adesao.valor_adesao_extenso = this.infoCliente.valor_adesao_extenso;
                    this.adesao.valor_adesao_adicionais = this.infoCliente.valor_adesao_adicionais;
                    this.adesao.valor_adesao_adicionais_extenso = this.infoCliente.valor_adesao_adicionais_extenso;
                    this.adesao.valor_total_adesao = this.infoCliente.valor_total_adesao;
                    this.adesao.valor_total_adesao_extenso = this.infoCliente.valor_total_adesao_extenso;
                    this.adesao.plano_id = this.infoCliente.plano_id;
                }
            }
        },
        calcularAdesoes(plano) {
            let portes = {
                p: 0,
                m: 0,
                g: 0,
                gg: 0
            };

            this.dadosPets.forEach(pet => {
                if (pet.porte === 'P') portes.p += 1;
                else if (pet.porte === 'M') portes.m += 1;
                else if (pet.porte === 'G') portes.g += 1;
                else if (pet.porte === 'GG') portes.gg += 1;
            });

            this.dadosPets.forEach(pet => {
                if (pet.porte === 'P' && portes.p > plano.limite_p) {
                    portes.p -= 1;
                    if (pet.modalidade_cremacao === 0) this.adesoes += plano.adicional_adesao_sem_resgate_p;
                    else this.adesoes += plano.adicional_adesao_com_resgate_p;
                }

                if (pet.porte === 'M' && portes.m > plano.limite_m) {
                    portes.m -= 1;
                    if (pet.modalidade_cremacao === 0) this.adesoes += plano.adicional_adesao_sem_resgate_m;
                    else this.adesoes += plano.adicional_adesao_com_resgate_m;
                }

                if (pet.porte === 'G' && portes.g > plano.limite_g) {
                    portes.g -= 1;
                    if (pet.modalidade_cremacao === 0) this.adesoes += plano.adicional_adesao_sem_resgate_g;
                    else this.adesoes += plano.adicional_adesao_com_resgate_g;
                }

                if (pet.porte === 'GG' && portes.gg > plano.limite_gg) {
                    portes.gg -= 1;
                    if (pet.modalidade_cremacao === 0) this.adesoes += plano.adicional_adesao_sem_resgate_gg;
                    else this.adesoes += plano.adicional_adesao_com_resgate_gg;
                }
            });
            
            this.adesao.valor_adesao = plano.valor_adesao;
            this.adesao.valor_adesao_adicionais = this.adesoes;
            this.adesao.valor_total_adesao = plano.valor_adesao + this.adesoes;
        },
        liberarBotao() {
            if (this.titulo === 'Contrato PET Primavera - Dourados - Plano PET individualizado') {
                if (this.adesao.valor_adesao !== null &&
                    this.adesao.valor_adesao_extenso !== null &&
                    this.adesao.valor_adesao_adicionais !== null &&
                    this.adesao.valor_adesao_adicionais_extenso !== null &&
                    this.adesao.valor_total_adesao !== null &&
                    this.adesao.valor_total_adesao_extenso !== null
                ) {
                    this.botao_liberado = true;
                }
            }

            if (this.titulo === 'TABLET VENDAS - TERMO DE INCLUSÃO DE DEPENDENTE PET PRIMAVERA ASSOCIADO') {
                if (this.adesao.valor_total_adesao !== null) {
                    this.botao_liberado = true;
                }
            }            
        },
        setup() {
            this.getPlanos();
        }
    },
    watch: {
        infoCliente(value) {
            if (value !== null && value !== undefined) this.preencherCampos();
        },
        adesao: {
            handler() {
                this.$emit('atualizar-botao-liberado', this.botao_liberado);
            },
            deep: true
        }
    },
    mounted() {
        this.setup();
    }
};
</script>

<style scoped>

.titulo-stepper {
    color: #007744;
    margin: auto;
}

</style>