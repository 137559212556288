<template>
  <div class="center margin">
    <v-img
      alt="Pet Primavera - WEB"
      class="shrink mr-2"
      contain
      src="img/fundo.png"
      width="200"
    />
	<div>
		<h1>Nenhum registro foi localizado.</h1>
	</div>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>


<style scoped>
.center {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;

  padding: 40px 40px;
}

.margin {
  margin-top: 10px;
}

img {
  width: 500px;
}

h1 {
  color: #a1a1a1;
  font-size: 1rem;
  text-align: center;
}
</style>