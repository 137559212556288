<template>
    <div>
        <v-card>
            <v-card-title class="mx-auto">
                <strong class="titulo-stepper">{{ titulo }}</strong>
            </v-card-title>

            <v-divider></v-divider>

            <v-form ref="form" class="pa-8 mx-auto">

                <v-row dense>
                    <v-col cols="12" md="6" v-if="[2, 4, 5, 6, 7, 8].includes(ordem)">
                        <v-autocomplete
                            v-model="tipo_plano"
                            item-value="descricao"
                            item-text="descricao"
                            label="Plano:"
                            color="#007744"
                            :items="planos"
                            prepend-icon="mdi-form-select"
                            auto-select-first
                            outlined
                            dense
                            chips
                            clearable
                            deletable-chips
                            @change="fillFields"
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <fieldset class="pa-4 mb-4">
                    <legend>Mensalidade:</legend>

                    <v-row dense>
                        <v-col cols="12" md="6" v-if="tipoContrato(campos.valor_mensalidade)">
                            <v-text-field
                                color="#007744"
                                v-model="mensalidade.valor_mensalidade"
                                :error-messages="error.valor_mensalidade"
                                label="Valor Mensalidade:"
                                aria-required
                                type="number"
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.valor_mensalidade_extenso)">
                            <v-text-field
                                color="#007744"
                                v-model="mensalidade.valor_mensalidade_extenso"
                                :error-messages="error.valor_mensalidade_extenso"
                                label="Valor Mensalidade por Extenso:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.valor_mensalidade_adicionais)">
                            <v-text-field
                                color="#007744"
                                v-model="mensalidade.valor_mensalidade_adicionais"
                                :error-messages="error.valor_mensalidade_adicionais"
                                label="Valor Mensalidade Adicionais:"
                                aria-required
                                type="number"
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.valor_mensalidade_adicionais_extenso)">
                            <v-text-field
                                color="#007744"
                                v-model="mensalidade.valor_mensalidade_adicionais_extenso"
                                :error-messages="error.valor_mensalidade_adicionais_extenso"
                                label="Valor Mensalidade Adicionais por Extenso:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.valor_total_mensalidade)">
                            <v-text-field
                                color="#007744"
                                v-model="mensalidade.valor_total_mensalidade"
                                :error-messages="error.valor_total_mensalidade"
                                label="Valor Total Mensalidade:"
                                aria-required
                                type="number"
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoContrato(campos.valor_total_mensalidade_extenso)">
                            <v-text-field
                                color="#007744"
                                v-model="mensalidade.valor_total_mensalidade_extenso"
                                :error-messages="error.valor_total_mensalidade_extenso"
                                label="Valor Total Mensalidade por Extenso:"
                                aria-required
                                type="text"
                                outlined
                                dense
                                @input="liberarBotao"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </fieldset>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import axios from '../../../../axios/service_private';

export default {
    name: 'mensalidade',
    data: () => ({
        tipo_plano: null,
        planos: [],
        mensalidade: {
            id: null,
            valor_mensalidade: null,
            valor_mensalidade_extenso: null,
            valor_mensalidade_adicionais: null,
            valor_mensalidade_adicionais_extenso: null,
            valor_total_mensalidade: null,
            valor_total_mensalidade_extenso: null
        },
        error: {
            valor_mensalidade: null,
            valor_mensalidade_extenso: null,
            valor_mensalidade_adicionais: null,
            valor_mensalidade_adicionais_extenso: null,
            valor_total_mensalidade: null,
            valor_total_mensalidade_extenso: null
        },
        botao_liberado: false,
        desabilitar: true,
        selectedPlano: null,
        adicionais: 0
    }),
    props: {
        campos: {
            type: Object
        },
        titulo: {
            type: String
        },
        ordem: {
            type: Number
        },
        infoCliente: {
            type: Object
        },
        dadosPets: {
            type: Array,
            default: []
        },
        jaCadastrado: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        emitDados() {
            this.$emit('dadosMensalidade', this.converte());
        },
        converte() {
            this.mensalidade.valor_mensalidade = Number(this.mensalidade.valor_mensalidade);
            this.mensalidade.valor_mensalidade_adicionais = Number(this.mensalidade.valor_mensalidade_adicionais);
            this.mensalidade.valor_total_mensalidade = Number(this.mensalidade.valor_total_mensalidade);
            return this.mensalidade;
        },
        tipoContrato(array) {
            return array.includes(this.ordem);
        },
        async getPlanos() {
            const response = await axios.get('api/v1/pet-primavera/planos-sem-pagination');
            if (response.data.status) {
                this.planos = response.data.planos;
            }
        },
        fillFields(selectedItem) {
            this.selectedPlano = this.planos.find(plano => plano.descricao === selectedItem);
            console.log(this.selectedPlano)
            //if (this.selectedPlano && !this.jaCadastrado[5]) {
            if (this.selectedPlano) {
                this.calcularMensalidades(this.selectedPlano);
            }
        },
        buscaPlano(id) {
            const plano = this.planos.find(plano => plano.id === id);
            return plano.descricao;
        },
        preencherCampos() {
            if (this.jaCadastrado[5]) {
                if (!this.infoCliente.contratante_id_mensalidade) {
                    this.mensalidade.valor_mensalidade = this.infoCliente.v_mensalidade;
                } else {
                    this.tipo_plano = this.buscaPlano(this.infoCliente.plano_id);
                    this.mensalidade.id = this.infoCliente.id_mensalidade_table;
                    this.mensalidade.valor_mensalidade = this.infoCliente.valor_mensalidade;
                    this.mensalidade.valor_mensalidade_extenso = this.infoCliente.valor_mensalidade_extenso;
                    this.mensalidade.valor_mensalidade_adicionais = this.infoCliente.valor_mensalidade_adicionais;
                    this.mensalidade.valor_mensalidade_adicionais_extenso = this.infoCliente.valor_mensalidade_adicionais_extenso;
                    this.mensalidade.valor_total_mensalidade = this.infoCliente.valor_total_mensalidade;
                    this.mensalidade.valor_total_mensalidade_extenso = this.infoCliente.valor_total_mensalidade_extenso;
                }
            }
        },
        liberarBotao() {
            if (this.titulo === 'Contrato PET Primavera - Dourados - Plano PET individualizado') {
                if (this.mensalidade.valor_mensalidade !== null &&
                    this.mensalidade.valor_mensalidade_extenso !== null &&
                    this.mensalidade.valor_mensalidade_adicionais !== null &&
                    this.mensalidade.valor_mensalidade_adicionais_extenso !== null &&
                    this.mensalidade.valor_total_mensalidade !== null &&
                    this.mensalidade.valor_total_mensalidade_extenso !== null
                ) {
                    this.botao_liberado = true;
                }
            }

            if (this.titulo === 'TABLET VENDAS - TERMO DE INCLUSÃO DE DEPENDENTE PET PRIMAVERA ASSOCIADO') {
                if (this.mensalidade.valor_total_mensalidade !== null) {
                    this.botao_liberado = true;
                }
            }            
        },
        calcularMensalidades(plano) {
            let portes = {
                p: 0,
                m: 0,
                g: 0,
                gg: 0
            };

            this.dadosPets.forEach(pet => {
                if (pet.porte === 'P') portes.p += 1;
                else if (pet.porte === 'M') portes.m += 1;
                else if (pet.porte === 'G') portes.g += 1;
                else if (pet.porte === 'GG') portes.gg += 1;
            });

            let adicionais = 0;

            this.dadosPets.forEach(pet => {
                if (pet.porte === 'P' && portes.p > plano.limite_p) {
                    portes.p -= 1;
                    if (pet.modalidade_cremacao === 0) adicionais += plano.adicional_mensalidade_sem_resgate_p;
                    else adicionais += plano.adicional_mensalidade_com_resgate_p;
                }

                if (pet.porte === 'M' && portes.m > plano.limite_m) {
                    portes.m -= 1;
                    if (pet.modalidade_cremacao === 0) adicionais += plano.adicional_mensalidade_sem_resgate_m;
                    else adicionais += plano.adicional_mensalidade_com_resgate_m;
                }

                if (pet.porte === 'G' && portes.g > plano.limite_g) {
                    portes.g -= 1;
                    if (pet.modalidade_cremacao === 0) adicionais += plano.adicional_mensalidade_sem_resgate_g;
                    else adicionais += plano.adicional_mensalidade_com_resgate_g;
                }

                if (pet.porte === 'GG' && portes.gg > plano.limite_gg) {
                    portes.gg -= 1;
                    if (pet.modalidade_cremacao === 0) adicionais += plano.adicional_mensalidade_sem_resgate_gg;
                    else adicionais += plano.adicional_mensalidade_com_resgate_gg;
                }
            });
            
            this.adicionais += adicionais;
            this.mensalidade.valor_mensalidade = plano.valor_mensalidade;
            this.mensalidade.valor_mensalidade_adicionais = this.adicionais;
            this.mensalidade.valor_total_mensalidade = plano.valor_mensalidade + this.adicionais;
        },
        setup() {
            this.getPlanos();
        }
    },
    watch: {
        // infoCliente(value) {
        //     if (value !== null && value !== undefined) this.preencherCampos();
        // },

        mensalidade: {
            handler() {
                this.$emit('atualizar-botao-liberado', this.botao_liberado);
            },
            deep: true
        }
    },
    mounted() {
        this.setup();
    }
};
</script>

<style scoped>

.buttom-steps {
    margin: 10px 10px;
}

.titulo-stepper {
    color: #007744;
    margin: auto;
}

</style>