<template class="">
  <div class="center margin">
    <v-img
      alt="Pet Primavera - WEB"
      class="shrink mr-2"
      contain
      :src="load ? 'img/logo.png' :  'img/fundo.png'"
      width="300"
    />

    <div>
      <h1 :class="load ? 'text_white' : ''">
        {{
          message != null ? message : "Pax Primavera Versão: " + pack.version
        }}
      </h1>
    </div>

    <div class="mt-4" v-if="load">
      <center>
        <v-progress-circular indeterminate color="#fff"></v-progress-circular>
      </center>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  props: {
    load: {
      type: Boolean,
      default: false,
      require: false,
    },  
    message: {
      type: String,
      default: null,
      require: false,
    },
  },
  data: () => ({
    pack: require("../../../package.json"),
  }),
};
</script>


<style scoped>
.center {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;

  padding: 40px 40px;
}

.margin {
  margin-top: 5rem;
}

img {
  width: 500px;
}

h1 {
  font-size: 1.2rem;
  color: #a1a1a1;
  text-align: center;
}

.text_white {
  color: #fff;
}
</style>