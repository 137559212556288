import { render, staticRenderFns } from "./loading.vue?vue&type=template&id=b09ca3b8&scoped=true&"
var script = {}
import style0 from "./loading.vue?vue&type=style&index=0&id=b09ca3b8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b09ca3b8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VImg,VProgressCircular})
