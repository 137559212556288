import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../../axios/service_private.js";
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
    mutations: {
        token: ({ commit }, token) => {
            commit('token_web_pet_primavera', token);
        }
    },
    actions: {
        async verifyToken() {
            let token = window.localStorage.getItem('token_web_pet_primavera');

            try {
                const verify = await axios.get('/api/v1/auth/validar-token', {}, {
                    headers: {
                        "authorization": `Bearer ${token}`
                    }
                });

                if (!verify.data.status) {
                    window.localStorage.clear();

                    Vue.$toast(verify.data.message, {
                        type: "error",
                    });
                    router.push('/entrar');
                }

            } catch (error) {
                console.log(error);
            }
        },
    },
    getters: {
        token: state => {
            return state.token
        }
    }
})